import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Container,
  Grid,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React, { useEffect, useState } from "react";
import { ImageViewer } from "react-image-viewer-dv";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  archiveLands,
  deleteLands,
  getSingleLand,
  reset,
  unarchiveLands,
} from "../../features/single_land/landSlice";
import LandInfoSideBar from "./LandInfoSideBar";
import LandOwnerDetails from "./LandOwnerDetails";

const LandInfo = ({ validity }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { landId } = useParams();

  useEffect(() => {
    dispatch(getSingleLand(landId));
  }, []);

  const { land, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.singleLand
  );

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    setTimeout(() => {
      dispatch(reset());
      setLoading(false);
    }, 3000);
  }, []);

  const unarchiveLandController = () => {
    Swal.fire({
      title: "Do you want to Re-activate this Land?",
      showCancelButton: true,
      confirmButtonText: "Activate",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(unarchiveLands(landId));
      }
    });
  };

  const archiveLandController = () => {
    Swal.fire({
      title: "Do you want to archive this Land?",
      showCancelButton: true,
      confirmButtonText: "Archive",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(archiveLands(landId));
      }
    });
  };
  const deleteLandController = () => {
    Swal.fire({
      title: "Do you want to delete this Land?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteLands(landId));
      }
    });
  };

  useEffect(() => {
    if (isError) {
      Swal.fire({
        icon: "error",
        title: "Ooops",
        text: message,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then(function () {
        dispatch(reset());
        navigate(`/dashboard/lands/landDetails/${landId}`);
      });
    }

    if (
      isSuccess &&
      (message.includes("This land has been activated successfully") ||
        message.includes("This land has been archived successfully") ||
        message.includes("This land has been deleted successfully"))
    ) {
      Swal.fire({
        icon: "success",
        title: "Congratulations",
        text: message,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then(function () {
        navigate(`/dashboard`);
        dispatch(reset());
      });
    }
  }, [isError, isSuccess, message, dispatch]);

  return (
    <Box component="div" sx={{ height: "90vh" }}>
      <Container>
        <Grid container>
          <Grid item xs={12} sm={2} md={2}>
            <LandOwnerDetails details={land?.OwnerDetails} />
          </Grid>

          <Grid item xs={12} sm={10} md={10}>
            <Box
              component="div"
              className="sm:p-5 p-5  bg-green-custom"
              sx={{ height: "90vh" }}>
              {loading ? (
                <Box className="flex items-center justify-center">
                  <Skeleton
                    variant="rectangular"
                    width={210}
                    height={20}
                    style={{
                      marginBottom: 6,
                      marginTop: 10,
                    }}
                  />
                </Box>
              ) : (
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    marginBottom: "1%",
                    textAlign: "center",
                  }}>
                  Land Details
                </Typography>
              )}

              <Grid container>
                <Grid item xs={12} sm={12} md={2}>
                  <Box
                    sx={{ width: "100%" }}
                    component="div"
                    className=" styled-border p-3">
                    {loading ? (
                      <Box>
                        {land?.lands?.Images?.map((item) => (
                          <Skeleton animation="wave" height={100} width="80%" />
                        ))}
                      </Box>
                    ) : (
                      <>
                        <ImageList variant="masonry" cols={2} gap={10}>
                          {land?.lands?.Images?.map((item, index) => (
                            <ImageListItem key={index}>
                              <ImageViewer>
                                <img
                                  src={`${item}`}
                                  srcSet={item}
                                  alt="House photos"
                                  loading="lazy"
                                  className="rounded-lg h-[15vh]"
                                />
                              </ImageViewer>
                            </ImageListItem>
                          ))}
                        </ImageList>
                        {user &&
                          user?._id === land?.lands?.UserId &&
                          land?.lands?.Listings === true && (
                            <Box className="flex items-center space-x-2 mt-3">
                              <Link to={`/editLand/${land?.lands?._id}`}>
                                <Button
                                  size="small"
                                  sx={{ fontSize: "9px" }}
                                  disableElevation
                                  variant="contained"
                                  endIcon={
                                    <EditIcon sx={{ fontSize: "5px" }} />
                                  }>
                                  Edit
                                </Button>
                              </Link>
                              {isLoading ? (
                                <>
                                  <Button
                                    size="small"
                                    sx={{ fontSize: "9px" }}
                                    disableElevation
                                    variant="contained"
                                    color="secondary"
                                    disabled
                                    endIcon={
                                      <ArchiveIcon sx={{ fontSize: "5px" }} />
                                    }
                                    onClick={archiveLandController}>
                                    Archive
                                  </Button>
                                  <Button
                                    size="small"
                                    sx={{ fontSize: "9px" }}
                                    disableElevation
                                    color="error"
                                    disabled
                                    variant="contained"
                                    startIcon={
                                      <DeleteIcon sx={{ fontSize: "5px" }} />
                                    }>
                                    Delete
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button
                                    size="small"
                                    sx={{ fontSize: "9px" }}
                                    disableElevation
                                    variant="contained"
                                    color="secondary"
                                    endIcon={
                                      <ArchiveIcon sx={{ fontSize: "5px" }} />
                                    }
                                    onClick={
                                      land?.lands?.Archive === true
                                        ? unarchiveLandController
                                        : archiveLandController
                                    }>
                                    {land?.lands?.Archive === true
                                      ? "Re-activate"
                                      : "Archive"}
                                  </Button>
                                  <Button
                                    size="small"
                                    sx={{ fontSize: "9px" }}
                                    disableElevation
                                    color="error"
                                    variant="contained"
                                    startIcon={
                                      <DeleteIcon sx={{ fontSize: "5px" }} />
                                    }
                                    onClick={deleteLandController}>
                                    Delete
                                  </Button>
                                </>
                              )}
                            </Box>
                          )}
                      </>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={10}>
                  {loading ? (
                    <Grid container className="px-5">
                      <Grid item xs={12} sm={6} md={6}>
                        <Skeleton animation="wave" height={300} width="80%" />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Skeleton animation="wave" height={300} width="80%" />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Box component="div" className="p-3 mt-5 sm:mt-0">
                          <div className="">
                            <Typography
                              variant="subtitle2"
                              sx={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                opacity: "80%",
                              }}>
                              Location
                            </Typography>
                            <Typography
                              variant="h6"
                              className={`${user ? null : "blur-sm"}`}
                              sx={{ fontWeight: "bold", fontSize: "15px" }}>
                              {land?.lands?.Country}, {land?.lands?.State},{" "}
                              {land?.lands?.Area},{" "}
                              {land?.lands?.Address || "null"}
                            </Typography>
                          </div>
                          <div className="mt-2">
                            <Typography
                              variant="subtitle2"
                              sx={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                opacity: "80%",
                              }}>
                              Unique Id of Land
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold", fontSize: "15px" }}>
                              {land?.lands?.UniqueId || "null"}
                            </Typography>
                          </div>
                          <div className="mt-2">
                            <Typography
                              variant="subtitle2"
                              sx={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                opacity: "80%",
                              }}>
                              Price of Land
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold", fontSize: "15px" }}>
                              {land?.lands?.Currency}{" "}
                              {land?.lands?.Price.toLocaleString() || "null"}
                            </Typography>
                          </div>
                          <div className="mt-2">
                            <Typography
                              variant="subtitle2"
                              sx={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                opacity: "80%",
                              }}>
                              Escrow Price of Land
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold", fontSize: "15px" }}>
                              {land?.lands?.Currency}{" "}
                              {land?.lands?.Escrow.toLocaleString() || "null"}
                            </Typography>
                          </div>
                          <div
                            className="mt-5 py-2"
                            style={{
                              borderTop: "1px dotted gray",
                            }}>
                            <Typography
                              variant="subtitle2"
                              sx={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                opacity: "80%",
                              }}>
                              Size of Land
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold", fontSize: "15px" }}>
                              {land?.lands?.Measurement}{" "}
                              {land?.lands?.Size || "null"}
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                      <LandInfoSideBar land={land} user={user} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LandInfo;
