import React from "react";
import { Box, Button, Paper, Typography, Divider } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import FileIcon from "@mui/icons-material/InsertDriveFile";
import { formatDate } from "../../helpers/functions";

const CofODocumentView = ({ CofO, getFileName }) => {
  return (
    <>
      {CofO.uploadOfCofOStatus === "uploaded" && (
        <Paper
          elevation={1}
          sx={{
            p: 2,
            mt: 2,
            border: "1px solid #e0e0e0",
            borderRadius: 1,
            bgcolor: "#f9fafb",
          }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1.5 }}>
            <FileIcon sx={{ color: "#22c55e", mr: 1 }} />
            <Typography variant="subtitle2" fontWeight="medium">
              Certificate of Occupancy Document
            </Typography>
          </Box>

          <Divider sx={{ mb: 2 }} />

          <Box
            sx={{
              display: "flex",
              gap: 1.5,
              flexDirection: { xs: "column", sm: "row" },
            }}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              startIcon={<VisibilityIcon />}
              href={CofO.uploadOfCofO.url}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                textTransform: "none",
                bgcolor: "#1976d2",
                "&:hover": { bgcolor: "#1565c0" },
              }}>
              View Document
            </Button>

            <Button
              size="small"
              variant="outlined"
              color="primary"
              startIcon={<DownloadIcon />}
              href={CofO.uploadOfCofO.url}
              download={getFileName(CofO.uploadOfCofO.url)}
              sx={{
                textTransform: "none",
                borderColor: "#1976d2",
                color: "#1976d2",
                "&:hover": {
                  borderColor: "#1565c0",
                  bgcolor: "rgba(25, 118, 210, 0.04)",
                },
              }}>
              Download
            </Button>
          </Box>

          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ display: "block", mt: 1.5 }}>
            Document uploaded on {formatDate(CofO.approvalDetails.approvalDate)}
          </Typography>
        </Paper>
      )}
    </>
  );
};

export default CofODocumentView;
