import React from "react";
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Skeleton,
  Alert,
} from "@mui/material";
import { InfoIcon } from "lucide-react";

const TitlePerfectionDetails = ({
  titlePerfectionData,
  propertyValue,
  state,
}) => {
  // Format number to currency
  const formatCurrency = (amount) => {
    return amount?.toLocaleString("en-NG", {
      style: "currency",
      currency: "NGN",
      maximumFractionDigits: 0,
    });
  };

  if (!titlePerfectionData) {
    return (
      <Box sx={{ mb: 3 }}>
        <Skeleton variant="rectangular" height={200} sx={{ borderRadius: 1 }} />
      </Box>
    );
  }

  const getAveragePrice = () => {
    const { minPrice, maxPrice } = titlePerfectionData;
    const averagePrice = (Number(minPrice) + Number(maxPrice)) / 2;
    return formatCurrency(averagePrice);
  };

  return (
    <Box>
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{
          mb: 3,
          borderColor: "#e2e8f0",
          borderRadius: "8px",
          overflow: "hidden",
        }}>
        <Table>
          <TableHead sx={{ bgcolor: "#f8fafc" }}>
            <TableRow>
              <TableCell
                sx={{ fontWeight: 600, color: "#334155", fontSize: "0.85rem" }}>
                Detail
              </TableCell>
              <TableCell
                align="right"
                sx={{ fontWeight: 600, color: "#334155", fontSize: "0.85rem" }}>
                Value
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  borderColor: "#f1f5f9",
                  fontSize: "0.85rem",
                  color: "#475569",
                }}>
                Property Location
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  borderColor: "#f1f5f9",
                  fontWeight: 500,
                  color: "#1e293b",
                  fontSize: "0.85rem",
                }}>
                <Chip
                  label={state}
                  size="small"
                  sx={{
                    bgcolor: "#f0f4ff",
                    color: "#3b82f6",
                    fontWeight: 600,
                    fontSize: "0.75rem",
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  borderColor: "#f1f5f9",
                  fontSize: "0.85rem",
                  color: "#475569",
                }}>
                Property Value
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  borderColor: "#f1f5f9",
                  fontWeight: 600,
                  color: "#1e293b",
                  fontSize: "0.85rem",
                }}>
                {formatCurrency(propertyValue)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  borderColor: "#f1f5f9",
                  fontSize: "0.85rem",
                  color: "#475569",
                }}>
                Estimated Price Range
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  borderColor: "#f1f5f9",
                  fontWeight: 600,
                  color: "#1e293b",
                  fontSize: "0.85rem",
                }}>
                {formatCurrency(titlePerfectionData.minPrice)} -{" "}
                {formatCurrency(titlePerfectionData.maxPrice)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  borderColor: "#f1f5f9",
                  fontSize: "0.85rem",
                  color: "#475569",
                }}>
                Average Price
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  borderColor: "#f1f5f9",
                  fontWeight: 600,
                  color: "#6d28d9",
                  fontSize: "0.85rem",
                }}>
                {getAveragePrice()}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  borderColor: "#f1f5f9",
                  fontSize: "0.85rem",
                  color: "#475569",
                }}>
                Estimated Timeframe
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  borderColor: "#f1f5f9",
                  fontWeight: 500,
                  color: "#1e293b",
                  fontSize: "0.85rem",
                }}>
                {titlePerfectionData.timeFrame}{" "}
                {titlePerfectionData.timeFrame === 1 ? "month" : "months"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Alert
        severity="warning"
        icon={<InfoIcon size={20} />}
        sx={{
          mb: 2,
          borderRadius: "8px",
          "& .MuiAlert-icon": {
            alignItems: "center",
            color: "#d97706",
          },
        }}>
        <Typography variant="body2" sx={{ fontSize: "0.85rem" }}>
          The final price will be determined after a thorough assessment of your
          property documents. You'll receive a detailed quotation for approval
          before any payment is required.
        </Typography>
      </Alert>
    </Box>
  );
};

export default TitlePerfectionDetails;
