import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunkWithHandler } from "../api";
import c_of_o_service from "./c_of_o_Service";

const initialState = {
  cofos: [],
  singleCofO: {},
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

export const getTitlePerfectionPrice = createAsyncThunkWithHandler(
  "c_of_o/getTitlePerfectionPrice",
  async (state, _) => {
    const data = await c_of_o_service.getTitlePerfectionPrice(state);
    return data;
  }
);

export const getAllCofO = createAsyncThunkWithHandler(
  "c_of_o/getAllCofO",
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    const userId = thunkAPI.getState().auth.user._id;
    const data = await c_of_o_service.getAllCofO(token, userId);
    return data;
  }
);

export const getSingleCofO = createAsyncThunkWithHandler(
  "c_of_o/getSingleCofO",
  async (cofoId, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    const userId = thunkAPI.getState().auth.user._id;
    const data = await c_of_o_service.getSingleCofO(token, { userId, cofoId });
    return data;
  }
);

export const requestCofO = createAsyncThunkWithHandler(
  "c_of_o/requestCofO",
  async (c_of_o_data, thunkAPI) => {
    const user = thunkAPI.getState().auth.user;
    const data = await c_of_o_service.requestCofO(user, c_of_o_data);
    return data;
  }
);

export const requestCofOWithUniqueId = createAsyncThunkWithHandler(
  "c_of_o/requestCofOWithUniqueId",
  async (c_of_o_data, thunkAPI) => {
    const user = thunkAPI.getState().auth.user;
    const data = await c_of_o_service.requestCofOWithUniqueId(
      user,
      c_of_o_data
    );
    return data;
  }
);

export const validatePropertyWithUniqueId = createAsyncThunkWithHandler(
  "c_of_o/validatePropertyWithUniqueId",
  async (c_of_o_data, thunkAPI) => {
    const user = thunkAPI.getState().auth.user;
    const data = await c_of_o_service.validatePropertyWithUniqueId(
      user,
      c_of_o_data
    );
    return data;
  }
);

export const finalPaymentCofO = createAsyncThunkWithHandler(
  "c_of_o/finalPaymentCofO",
  async (c_of_o_data, thunkAPI) => {
    const user = thunkAPI.getState().auth.user;
    const data = await c_of_o_service.finalPaymentCofO(user, c_of_o_data);
    return data;
  }
);

const c_of_o_Slice = createSlice({
  name: "c_of_o",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestCofO.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(requestCofO.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = "Request sent successfully";
        state.singleCofO = action.payload;
      })
      .addCase(requestCofO.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(validatePropertyWithUniqueId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(validatePropertyWithUniqueId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = "Property is eligible for CofO application";
      })
      .addCase(validatePropertyWithUniqueId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(requestCofOWithUniqueId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(requestCofOWithUniqueId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = "Request sent successfully";
        state.singleCofO = action.payload;
      })
      .addCase(requestCofOWithUniqueId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(getAllCofO.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCofO.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.cofos = action.payload;
      })
      .addCase(getAllCofO.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(getSingleCofO.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleCofO.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.singleCofO = action.payload;
      })
      .addCase(getSingleCofO.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(finalPaymentCofO.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(finalPaymentCofO.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = "Final Payment successful";
      })
      .addCase(finalPaymentCofO.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      });
  },
});

export const { reset } = c_of_o_Slice.actions;
export default c_of_o_Slice.reducer;
