import React, { useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  Container,
  CssBaseline,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout, reset } from "../features/auth/authSlice";
import UpdateProfileForm from "./Login/UpdateProfileForm";
import UpdatePasswordForm from "./Login/UpdatePasswordForm";
import { Avatar } from "antd";

export default function DashboardNavbar() {
  const [mobileOpen, setMobileOpen] = useState(false); // For Drawer
  const [anchorEl, setAnchorEl] = useState(null); // For profile menu

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  // Handlers
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handleLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate("/");
    handleMenuClose();
    setMobileOpen(false);
  };

  // Navigation items
  const navItems = [
    { label: "Properties", path: "/dashboard" },
    { label: "Sell", path: "/sellLand" },
    { label: "Verify", path: "/dashboard/verify" },
    { label: "Escrow", path: "/escrowLand" },
    { label: "Home", path: "/" },
  ];

  // Drawer content for mobile
  const drawer = (
    <Box sx={{ width: 250, p: 2 }}>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
        <Link to="/" onClick={() => setMobileOpen(false)}>
          <span className="text-green-600">Lock.</span>
          <span className="text-neutral-900">your</span>
          <span className="text-blue-800">.land</span>
        </Link>
      </Typography>
      <List>
        {navItems.map((item) => (
          <ListItem
            key={item.label}
            component={Link}
            to={item.path}
            onClick={() => setMobileOpen(false)}
            sx={{
              color: location.pathname === item.path ? "#16a34a" : "#1f2937",
              fontWeight: "bold",
              "&:hover": { color: "#16a34a" },
            }}>
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
        <ListItem
          component={Link}
          to="/cofoApplication?type=new"
          onClick={() => setMobileOpen(false)}
          sx={{ color: "#1f2937", fontWeight: "bold" }}>
          <ListItemText primary="C of O Application" />
        </ListItem>
        <Divider sx={{ my: 1 }} />
        <ListItem onClick={handleLogout} sx={{ color: "#1f2937" }}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <CssBaseline />
      <AppBar
        position="sticky"
        sx={{
          bgcolor: "#ffffff",
          boxShadow: "0 2px 10px rgba(0,0,0,0.05)",
          top: 0,
          zIndex: 1200,
        }}>
        <Container maxWidth="lg">
          <Toolbar disableGutters sx={{ py: 1 }}>
            {/* Logo */}
            <Typography
              variant="h6"
              component={Link}
              to="/"
              sx={{
                flexGrow: { xs: 1, md: 0 },
                fontWeight: "bold",
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
              }}>
              <span className="text-green-600">Lock.</span>
              <span className="text-neutral-900">your</span>
              <span className="text-blue-800">.land</span>
            </Typography>

            {/* Desktop Navigation */}
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                flexGrow: 1,
                justifyContent: "center",
                gap: 3,
              }}>
              {navItems.map((item) => (
                <Button
                  key={item.label}
                  component={Link}
                  to={item.path}
                  sx={{
                    color:
                      location.pathname === item.path ? "#16a34a" : "#1f2937",
                    fontWeight: "bold",
                    textTransform: "none",
                    fontSize: "1rem",
                    "&:hover": {
                      color: "#16a34a",
                      bgcolor: "transparent",
                    },
                  }}>
                  {item.label}
                </Button>
              ))}
            </Box>

            {/* Desktop Buttons */}
            <Box sx={{ display: { xs: "none", md: "flex" }, gap: 2 }}>
              <Button
                component={Link}
                to="/cofoApplication?type=new"
                variant="contained"
                sx={{
                  bgcolor: "#000",
                  color: "#fff",
                  textTransform: "none",
                  fontWeight: "bold",
                  "&:hover": { bgcolor: "#333" },
                }}>
                C of O Application
              </Button>
              <Button
                onClick={handleMenuOpen}
                sx={{
                  minWidth: 0,
                  p: 1,
                  color: "#1f2937",
                  "&:hover": { bgcolor: "#f5f5f5" },
                }}>
                <Avatar sx={{ bgcolor: "#16a34a", width: 32, height: 32 }}>
                  {user?.name?.charAt(0) || "U"}
                </Avatar>
              </Button>
            </Box>

            {/* Mobile Menu Button */}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{ display: { md: "none" }, color: "#1f2937" }}>
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </Container>

        {/* Profile Menu (Desktop) */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            sx: {
              mt: 1,
              boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
            },
          }}>
          {user?.VerifiedUser !== "completed" && (
            <MenuItem>
              <UpdateProfileForm onClose={handleMenuClose} />
            </MenuItem>
          )}
          <MenuItem>
            <UpdatePasswordForm onClose={handleMenuClose} />
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </AppBar>

      {/* Mobile Drawer */}
      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{ display: { md: "none" } }}>
        {drawer}
      </Drawer>
    </>
  );
}
