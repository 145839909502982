import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { message } from "antd";
import axios from "axios"; // Assuming you're using axios for API calls
import { useDispatch, useSelector } from "react-redux";
import { OldCofOvalidationSchema } from "../../utils/Index";
import { useNavigate } from "react-router-dom";
import {
  requestCofOWithUniqueId,
  reset,
  validatePropertyWithUniqueId,
} from "../../features/CofO/c_of_o_Slice";

const useOldPropertyApplicationForm = () => {
  const { user } = useSelector((state) => state.auth);
  const [handleOpenPaymentModal, setOpenPaymentModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [propertyData, setPropertyData] = useState();

  const {
    singleCofO,
    isLoading,
    isSuccess,
    isError,
    message: msg,
  } = useSelector((state) => state.c_of_o);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Validation schema

  // Form submission handler
  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append("uniqueId", values.uniqueId);
    formData.append("passportPhoto", values.passportPhoto);
    formData.append("propertyType", values.propertyType);

    setPropertyData(formData);
    dispatch(
      validatePropertyWithUniqueId({
        propertyType: values.propertyType,
        uniqueId: values.uniqueId,
      })
    );
  };

  function handleInitializeCofO(reference) {
    // Dispatch with FormData
    dispatch(requestCofOWithUniqueId({ propertyData, reference }));
    setLoading(true);
  }

  // Render error message helper
  const renderErrorMessage = (fieldName) => {
    return (
      formik.touched[fieldName] &&
      formik.errors[fieldName] && (
        <div style={{ color: "red", marginTop: 8 }}>
          {formik.errors[fieldName]}
        </div>
      )
    );
  };

  // Formik configuration
  const formik = useFormik({
    initialValues: {
      uniqueId: "",
      passportPhoto: "",
      propertyType: "", // Default empty to require user selection
    },
    validationSchema: OldCofOvalidationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (isSuccess && msg == "Request sent successfully") {
      message.success(msg);
      dispatch(reset());
      navigate(`/dashboard/cofoApplication`);
    }

    if (isSuccess && msg == "Property is eligible for CofO application") {
      message.success(msg);
      setOpenPaymentModal(true);
      dispatch(reset());
    }

    if (isError) {
      message.error(msg);
      dispatch(reset());
    }
  }, [isSuccess, isError]);

  return {
    formik,
    renderErrorMessage,
    isLoading,
    user,
    handleInitializeCofO,
    setOpenPaymentModal,
    handleOpenPaymentModal,
    loading,
  };
};

export default useOldPropertyApplicationForm;
