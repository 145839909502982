// src/components/InactivityWrapper.js
import React from "react";
import { useInactivityLogout } from "../hooks/useCheckInactivity";

const InactivityWrapper = ({ children }) => {
  useInactivityLogout(); // Call the hook here
  return <>{children}</>; // Render the wrapped content
};

export default InactivityWrapper;
