import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import DownloadIcon from "@mui/icons-material/Download";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PaymentIcon from "@mui/icons-material/Payment";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import DashboardNavbar from "../components/DashboardNavbar";
import Loader from "../components/addons/Loader";
import NotFound from "../components/addons/NotFound";
import PaymentModal from "../components/addons/PaymentModal";
import useCofOApplicationForm from "../hooks/form-hooks/useCofOApplicationForm";
import CofOProgressTracker from "../components/CofO/CofOProgressTracker";
import CofODocumentView from "../components/CofO/CofODocumentView";
import { formatDate } from "../helpers/functions";

// TabPanel component for tab content
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`cofo-tabpanel-${index}`}
      aria-labelledby={`cofo-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const DashboardRealtorSingleCofO = () => {
  const {
    isLoading,
    tabValue,
    setTabValue,
    singleCofO,
    isError,
    handleOpenPaymentModal,
    setOpenPaymentModal,
    handlePaymentOfCofO,
  } = useCofOApplicationForm();

  // Function to get status color
  const getStatusColor = (status) => {
    switch (status) {
      case "approved":
        return "success";
      case "rejected":
        return "error";
      case "awaiting":
        return "warning";
      case "under_review":
        return "info";
      default:
        return "default";
    }
  };

  // Function to get payment status color
  const getPaymentStatusColor = (status) => {
    switch (status) {
      case "paid":
        return "success";
      case "pending":
        return "warning";
      default:
        return "default";
    }
  };

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Function to get file name from URL
  const getFileName = (url) => {
    if (!url) return "";
    const parts = url.split("/");
    const fileNameWithParams = parts[parts.length - 1];
    // Remove any parameters after the filename
    return fileNameWithParams.split("?")[0];
  };

  if (isLoading) {
    return (
      <>
        <DashboardNavbar />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}>
          <Loader />
        </Box>
      </>
    );
  }

  if (isError || !singleCofO || !singleCofO?.data) {
    return (
      <>
        <DashboardNavbar />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}>
          <NotFound message="Certificate of Occupancy not found" />
        </Box>
      </>
    );
  }

  const { CofO, agent } = singleCofO?.data;

  return (
    <>
      <DashboardNavbar />
      <Box
        component="div"
        className="custom-scrollbar"
        sx={{
          height: "90vh",
          overflow: "auto",
        }}>
        <Container maxWidth="lg" sx={{ py: 4 }}>
          {/* Header with back button */}
          <Box sx={{ mb: 3 }}>
            <Link
              to="/dashboard/cofoApplication"
              style={{ textDecoration: "none" }}>
              <Button
                variant="text"
                sx={{ fontWeight: "bold", color: "black", mb: 2 }}
                startIcon={<Box sx={{ transform: "rotate(180deg)" }}>→</Box>}>
                Back to C of O Applications
              </Button>
            </Link>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={8}>
                <Typography
                  variant="h5"
                  component="h1"
                  sx={{ fontWeight: "bold" }}>
                  C of O Application: {CofO?.applicationId}
                </Typography>
                <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                  <Chip
                    label={CofO?.status.toUpperCase()}
                    color={getStatusColor(CofO?.status)}
                    size="small"
                  />
                  <Chip
                    label={`Payment: ${CofO?.paymentStatus.toUpperCase()}`}
                    color={getPaymentStatusColor(CofO?.paymentStatus)}
                    size="small"
                  />
                  <Chip
                    label={`Property: ${CofO?.propertyType.toUpperCase()}`}
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                sx={{ textAlign: { xs: "left", md: "right" } }}>
                <Typography variant="body2" color="text.secondary">
                  Submitted: {formatDate(CofO?.submissionDate)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Last Updated: {formatDate(CofO?.lastUpdated)}
                </Typography>
              </Grid>
            </Grid>

            {CofO?.uploadOfCofOStatus === "uploaded" && (
              <CofODocumentView CofO={CofO} getFileName={getFileName} />
            )}
          </Box>

          {/* Main content */}
          <Grid container spacing={3}>
            {/* Left side - Main content */}
            <Grid item xs={12} md={8}>
              <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons="auto">
                  <Tab label="Application Details" />
                  <Tab label="Documents" />
                  <Tab label="Site Photos" />
                  <Tab label="Payment" />
                </Tabs>
              </Box>

              {/* Application Details Tab */}
              <TabPanel value={tabValue} index={0}>
                <Paper elevation={0} variant="outlined" sx={{ p: 3, mb: 3 }}>
                  <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                    Application Summary
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" color="text.secondary">
                        Application ID
                      </Typography>
                      <Typography variant="body1" sx={{ mb: 2 }}>
                        {CofO?.applicationId}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" color="text.secondary">
                        Property Type
                      </Typography>
                      <Typography variant="body1" sx={{ mb: 2 }}>
                        {CofO?.propertyType.toUpperCase()}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" color="text.secondary">
                        Status
                      </Typography>
                      <Typography variant="body1" sx={{ mb: 2 }}>
                        {CofO?.status.toUpperCase()}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" color="text.secondary">
                        C of O Upload Status
                      </Typography>
                      <Typography variant="body1" sx={{ mb: 2 }}>
                        {CofO?.uploadOfCofOStatus.toUpperCase()}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" color="text.secondary">
                        Submission Date
                      </Typography>
                      <Typography variant="body1" sx={{ mb: 2 }}>
                        {formatDate(CofO?.submissionDate)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" color="text.secondary">
                        Last Updated
                      </Typography>
                      <Typography variant="body1" sx={{ mb: 2 }}>
                        {formatDate(CofO?.lastUpdated)}
                      </Typography>
                    </Grid>
                  </Grid>

                  {CofO?.reviewNotes && CofO?.reviewNotes.length > 0 && (
                    <Box sx={{ mt: 3 }}>
                      <Typography
                        variant="h6"
                        sx={{ mb: 2, fontWeight: "bold" }}>
                        Review Notes
                      </Typography>
                      <List>
                        {CofO?.reviewNotes.map((note, index) => (
                          <ListItem
                            key={index}
                            divider={index < CofO?.reviewNotes.length - 1}>
                            <ListItemText
                              primary={note.comment}
                              secondary={`By ${note.reviewer} on ${formatDate(
                                note.date
                              )}`}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  )}
                </Paper>
              </TabPanel>

              {/* Documents Tab */}
              <TabPanel value={tabValue} index={1}>
                <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                  Required Documents
                </Typography>
                <Grid container spacing={2}>
                  {/* Passport Photo */}
                  <Grid item xs={12} sm={6}>
                    <Card variant="outlined">
                      <CardContent>
                        <Box
                          sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                          <PersonIcon sx={{ mr: 1 }} />
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "bold" }}>
                            Passport Photo
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            mb: 2,
                          }}>
                          <img
                            src={CofO?.passportPhoto.url}
                            alt="Passport Photo"
                            style={{
                              width: "120px",
                              height: "120px",
                              objectFit: "cover",
                            }}
                          />
                        </Box>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ mb: 1 }}>
                          Uploaded: {formatDate(CofO?.passportPhoto.uploadDate)}
                        </Typography>
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <Button
                            size="small"
                            variant="outlined"
                            startIcon={<VisibilityIcon />}
                            href={CofO?.passportPhoto.url}
                            target="_blank"
                            rel="noopener noreferrer">
                            View
                          </Button>
                          <Button
                            size="small"
                            variant="outlined"
                            startIcon={<DownloadIcon />}
                            href={CofO?.passportPhoto.url}
                            download={getFileName(CofO?.passportPhoto.url)}>
                            Download
                          </Button>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Deed of Sale */}
                  <Grid item xs={12} sm={6}>
                    <Card variant="outlined">
                      <CardContent>
                        <Box
                          sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                          <DocumentScannerIcon sx={{ mr: 1 }} />
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "bold" }}>
                            Deed of Sale
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            mb: 2,
                          }}>
                          <Box
                            sx={{
                              width: "120px",
                              height: "120px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              bgcolor: "#f5f5f5",
                              borderRadius: "4px",
                            }}>
                            <InsertDriveFileIcon
                              sx={{ fontSize: 60, color: "#757575" }}
                            />
                          </Box>
                        </Box>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ mb: 1 }}>
                          Uploaded: {formatDate(CofO?.deedOfSale.uploadDate)}
                        </Typography>
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <Button
                            size="small"
                            variant="outlined"
                            startIcon={<VisibilityIcon />}
                            href={CofO?.deedOfSale.url}
                            target="_blank"
                            rel="noopener noreferrer">
                            View
                          </Button>
                          <Button
                            size="small"
                            variant="outlined"
                            startIcon={<DownloadIcon />}
                            href={CofO?.deedOfSale.url}
                            download={getFileName(CofO?.deedOfSale.url)}>
                            Download
                          </Button>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Survey Plan */}
                  <Grid item xs={12} sm={6}>
                    <Card variant="outlined">
                      <CardContent>
                        <Box
                          sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                          <DocumentScannerIcon sx={{ mr: 1 }} />
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: "bold" }}>
                            Survey Plan
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            mb: 2,
                          }}>
                          <Box
                            sx={{
                              width: "120px",
                              height: "120px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              bgcolor: "#f5f5f5",
                              borderRadius: "4px",
                            }}>
                            <InsertDriveFileIcon
                              sx={{ fontSize: 60, color: "#757575" }}
                            />
                          </Box>
                        </Box>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ mb: 1 }}>
                          Uploaded: {formatDate(CofO?.surveyPlan.uploadDate)}
                        </Typography>
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <Button
                            size="small"
                            variant="outlined"
                            startIcon={<VisibilityIcon />}
                            href={CofO?.surveyPlan.url}
                            target="_blank"
                            rel="noopener noreferrer">
                            View
                          </Button>
                          <Button
                            size="small"
                            variant="outlined"
                            startIcon={<DownloadIcon />}
                            href={CofO?.surveyPlan.url}
                            download={getFileName(CofO?.surveyPlan.url)}>
                            Download
                          </Button>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Additional Documents */}
                  {CofO?.additionalDocuments &&
                    CofO?.additionalDocuments.length > 0 && (
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          sx={{ mt: 2, mb: 2, fontWeight: "bold" }}>
                          Additional Documents
                        </Typography>
                        <Grid container spacing={2}>
                          {CofO?.additionalDocuments.map((doc, index) => (
                            <Grid item xs={12} sm={6} key={index}>
                              <Card variant="outlined">
                                <CardContent>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      mb: 2,
                                    }}>
                                    <DocumentScannerIcon sx={{ mr: 1 }} />
                                    <Typography
                                      variant="subtitle1"
                                      sx={{ fontWeight: "bold" }}>
                                      {doc.documentType.replace(/_/g, " ")}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      mb: 2,
                                    }}>
                                    <Box
                                      sx={{
                                        width: "120px",
                                        height: "120px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        bgcolor: "#f5f5f5",
                                        borderRadius: "4px",
                                      }}>
                                      <InsertDriveFileIcon
                                        sx={{ fontSize: 60, color: "#757575" }}
                                      />
                                    </Box>
                                  </Box>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{ mb: 1 }}>
                                    Uploaded: {formatDate(doc.uploadDate)}
                                  </Typography>
                                  <Box sx={{ display: "flex", gap: 1 }}>
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      startIcon={<VisibilityIcon />}
                                      href={doc.url}
                                      target="_blank"
                                      rel="noopener noreferrer">
                                      View
                                    </Button>
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      startIcon={<DownloadIcon />}
                                      href={doc.url}
                                      download={getFileName(doc.url)}>
                                      Download
                                    </Button>
                                  </Box>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    )}
                </Grid>
              </TabPanel>

              {/* Site Photos Tab */}
              <TabPanel value={tabValue} index={2}>
                <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                  Site Photos
                </Typography>
                <Grid container spacing={2}>
                  {CofO?.sitePhotos &&
                    CofO?.sitePhotos.map((photo, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card variant="outlined">
                          <CardMedia
                            component="img"
                            height="180"
                            image={photo.url}
                            alt={`Site Photo ${index + 1}`}
                          />
                          <CardContent>
                            <Typography variant="body2" color="text.secondary">
                              Uploaded: {formatDate(photo.uploadDate)}
                            </Typography>
                            <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                              <Button
                                size="small"
                                variant="outlined"
                                startIcon={<VisibilityIcon />}
                                href={photo.url}
                                target="_blank"
                                rel="noopener noreferrer">
                                View
                              </Button>
                              <Button
                                size="small"
                                variant="outlined"
                                startIcon={<DownloadIcon />}
                                href={photo.url}
                                download={getFileName(photo.url)}>
                                Download
                              </Button>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                </Grid>
              </TabPanel>

              {/* Payment Tab */}
              <TabPanel value={tabValue} index={3}>
                <Paper elevation={0} variant="outlined" sx={{ p: 3, mb: 3 }}>
                  <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                    Payment Information
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" color="text.secondary">
                        Payment Status
                      </Typography>
                      <Chip
                        label={CofO?.paymentStatus.toUpperCase()}
                        color={getPaymentStatusColor(CofO?.paymentStatus)}
                        sx={{ mt: 1, mb: 2 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" color="text.secondary">
                        {CofO?.paymentStatus == "paid"
                          ? "Amount Paid"
                          : "Payment Due"}
                      </Typography>
                      {!CofO?.paymentDue ? (
                        <Typography
                          variant="h6"
                          sx={{ mb: 2, fontWeight: "bold", color: "#22c55e" }}>
                          Not Available Yet
                        </Typography>
                      ) : (
                        <Typography
                          variant="h6"
                          sx={{ mb: 2, fontWeight: "bold", color: "#22c55e" }}>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "NGN", // Change to your preferred currency
                          }).format(CofO?.paymentDue)}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  {CofO?.status === "awaiting" && (
                    <Box sx={{ mt: 3 }}>
                      <Button
                        variant="contained"
                        onClick={() => setOpenPaymentModal(true)}
                        sx={{
                          bgcolor: "#22c55e",
                          "&:hover": { bgcolor: "#1b9e4d" },
                          px: 4,
                          py: 1.2,
                          fontWeight: "bold",
                          borderRadius: 2,
                          textTransform: "none",
                        }}
                        startIcon={<PaymentIcon />}>
                        Make Payment
                      </Button>

                      <PaymentModal
                        handleOpenPaymentModal={handleOpenPaymentModal}
                        setOpenPaymentModal={setOpenPaymentModal}
                        handlePaymentOfCofO={handlePaymentOfCofO}
                        amount={CofO?.paymentDue}
                        CofOApplicationPayment={true}
                      />
                    </Box>
                  )}
                </Paper>
              </TabPanel>
            </Grid>

            {/* Right side - Agent Details and Activity */}
            <Grid item xs={12} md={4}>
              {/* Agent Information */}
              {CofO?.agent && (
                <Paper elevation={0} variant="outlined" sx={{ p: 3, mb: 3 }}>
                  <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                    Assigned Agent
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Box
                      sx={{
                        width: 50,
                        height: 50,
                        borderRadius: "50%",
                        bgcolor: "#f0f9f0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mr: 2,
                      }}>
                      <PersonIcon sx={{ color: "#22c55e" }} />
                    </Box>
                    <Box>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "bold" }}>
                        {agent?.Firstname} {agent?.Lastname}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        License: {agent?.AgentLicense}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              )}

              {/* Application Status */}
              <Paper elevation={0} variant="outlined" sx={{ p: 3, mb: 3 }}>
                <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                  Application Status
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <Chip
                    label={CofO?.status.toUpperCase()}
                    color={getStatusColor(CofO?.status)}
                    sx={{ mr: 1 }}
                  />
                  <Typography variant="body2" color="text.secondary">
                    Last updated {formatDate(CofO?.lastUpdated)}
                  </Typography>
                </Box>
                <CofOProgressTracker CofO={CofO} />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default DashboardRealtorSingleCofO;
