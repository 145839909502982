import {
  CheckCircleOutlined,
  FileProtectOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
import { Card, Col, Row, Space, Typography } from "antd";

const { Title, Paragraph, Text } = Typography;

const InfoCard = () => {
  return (
    <div>
      {" "}
      <Row gutter={[24, 24]} style={{ marginBottom: 48, marginTop: 10 }}>
        <Col xs={24} md={8}>
          <Card>
            <Space direction="vertical" size="middle">
              <SafetyCertificateOutlined
                style={{ fontSize: 36, color: "#1890ff" }}
              />
              <Title level={3}>What is a C of O?</Title>
              <Paragraph>
                A Certificate of Occupancy (C of O) is the most important land
                document in Nigeria, proving authentic land ownership.
              </Paragraph>
            </Space>
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card>
            <Space direction="vertical" size="middle">
              <FileProtectOutlined style={{ fontSize: 36, color: "#1890ff" }} />
              <Title level={3}>Why is it Important?</Title>
              <Paragraph>
                A C of O protects your property rights, increases your land's
                value, and is essential for major transactions.
              </Paragraph>
            </Space>
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card>
            <Space direction="vertical" size="middle">
              <CheckCircleOutlined style={{ fontSize: 36, color: "#1890ff" }} />
              <Title level={3}>Why LockYourLand?</Title>
              <Paragraph>
                We streamline the complex C of O process through our proven
                system of due diligence and efficient documentation.
              </Paragraph>
            </Space>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default InfoCard;
