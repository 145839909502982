import React from "react";
import {
  Button,
  Grid,
  Paper,
  Box,
  Avatar,
  Stack,
  Divider,
} from "@mui/material";
import PaymentModal from "../../components/addons/PaymentModal";
import { Typography } from "@mui/material";
import useHandleTitlePerfectionPayment from "../../hooks/useHandleTitlePerfectionPayment";
import { CrownIcon, ArrowRightIcon, CheckIcon } from "lucide-react";

const InitiateTitlePerfectionPayment = ({ property }) => {
  const {
    handleOpenPaymentModal,
    handleTitlePerfectionPayment,
    isLoading,
    setOpenPaymentModal,
  } = useHandleTitlePerfectionPayment({ docId: property?.document?._id });

  const perfectionAmount = property?.document?.TitlePerfectionConfirmedPrice;

  // Format the amount with commas for thousands
  const formattedAmount = perfectionAmount.toLocaleString("en-US", {
    style: "currency",
    currency: "NGN",
    maximumFractionDigits: 0,
  });

  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: 2,
        border: "1px solid #e2e8f0",
        background: "linear-gradient(145deg, #ffffff 0%, #f8fafc 100%)",
        overflow: "hidden",
        mb: 3,
      }}>
      {/* Header */}
      <Box
        sx={{
          p: 2,
          bgcolor: "#8b5cf6",
          background: "linear-gradient(to right, #8b5cf6, #6d28d9)",
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}>
        <Avatar
          sx={{
            bgcolor: "white",
            width: 40,
            height: 40,
            "& svg": { color: "#6d28d9" },
          }}>
          <CrownIcon size={22} />
        </Avatar>
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              color: "white",
              fontWeight: 600,
              fontSize: "1rem",
            }}>
            Title Perfection Payment
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "rgba(255, 255, 255, 0.8)",
              fontSize: "0.8rem",
            }}>
            Complete your property documentation
          </Typography>
        </Box>
      </Box>

      {/* Content */}
      <Box sx={{ p: 3 }}>
        <Typography
          variant="body1"
          sx={{
            color: "#4b5563",
            mb: 3,
            fontSize: "0.9rem",
          }}>
          The title perfection pricing document has been prepared and confirmed.
          Please complete the following payment to proceed with title
          perfection.
        </Typography>

        <Stack
          direction="column"
          spacing={1.5}
          sx={{
            mb: 3,
            p: 2,
            borderRadius: 2,
            bgcolor: "#f5f3ff",
            border: "1px solid #ede9fe",
          }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <CheckIcon size={16} color="#7c3aed" />
            <Typography
              variant="body2"
              sx={{
                color: "#6d28d9",
                fontSize: "0.85rem",
              }}>
              All documents verified and approved
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <CheckIcon size={16} color="#7c3aed" />
            <Typography
              variant="body2"
              sx={{
                color: "#6d28d9",
                fontSize: "0.85rem",
              }}>
              Certificate of Occupancy to be processed
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <CheckIcon size={16} color="#7c3aed" />
            <Typography
              variant="body2"
              sx={{
                color: "#6d28d9",
                fontSize: "0.85rem",
              }}>
              Secure official government registration
            </Typography>
          </Box>
        </Stack>

        <Divider sx={{ my: 3 }} />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}>
          <Typography
            variant="subtitle1"
            sx={{
              color: "#1f2937",
              fontWeight: 600,
            }}>
            Total Amount:
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: "#6d28d9",
              fontWeight: 700,
            }}>
            {formattedAmount}
          </Typography>
        </Box>

        <Button
          variant="contained"
          disableElevation
          disabled={isLoading}
          fullWidth
          endIcon={<ArrowRightIcon size={18} />}
          onClick={() => setOpenPaymentModal(!handleOpenPaymentModal)}
          sx={{
            py: 1.5,
            backgroundColor: "#6d28d9",
            color: "#fff",
            textTransform: "none",
            fontWeight: 600,
            fontSize: "0.9rem",
            borderRadius: "8px",
            boxShadow: "0 4px 6px rgba(109, 40, 217, 0.2)",
            "&:hover": {
              backgroundColor: "#5b21b6",
              transform: "translateY(-1px)",
              boxShadow: "0 6px 8px rgba(109, 40, 217, 0.25)",
            },
          }}>
          {isLoading ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <span className="animate-pulse mr-2">●</span>
              <span>Processing...</span>
            </Box>
          ) : (
            "Complete Title Perfection Payment"
          )}
        </Button>
      </Box>

      <PaymentModal
        handleOpenPaymentModal={handleOpenPaymentModal}
        setOpenPaymentModal={setOpenPaymentModal}
        handleTitlePerfectionPayment={handleTitlePerfectionPayment}
        amount={perfectionAmount}
        titlePerfection={true}
      />
    </Paper>
  );
};

export default InitiateTitlePerfectionPayment;
