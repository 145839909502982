import React from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
  Paper,
  CircularProgress,
  IconButton,
  Fade,
  Backdrop,
  Divider,
  Grid,
  Card,
  CardContent,
  Alert,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getTitlePerfectionPrice,
  requestTitlePerfectionPrice,
  reset,
} from "../../features/titlePerfection/titlePerfectionSlice";
import TitlePerfectionDetails from "./TitlePerfectionDetails";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CrownIcon, XIcon, CheckIcon, HelpCircleIcon } from "lucide-react";

const TitlePerfectionModal = ({
  handleCloseTitlePerfectionModal,
  openTitlePerfectionModal,
  State,
  propertyValue,
  DocId,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { user } = useSelector((state) => state.auth);
  const { isLoading } = useSelector((state) => state.documents);
  const {
    isLoading: titlePerfectionLoading,
    singleTitlePerfection,
    isSuccess,
    isError,
    message,
  } = useSelector((state) => state.titlePerfection);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTitlePerfectionPrice(State));
  }, []);

  useEffect(() => {
    if (isSuccess && message === "Request sent successfully") {
      toast.success(message);
      dispatch(reset());
      navigate(0);
    }

    if (isError) {
      toast.error(message);
      dispatch(reset());
    }
  }, [isSuccess, isError, message]);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : "650px",
    maxWidth: "750px",
    outline: "none",
    borderRadius: "12px",
    overflow: "hidden",
    maxHeight: "90vh",
  };

  const handleSubmit = () => {
    dispatch(requestTitlePerfectionPrice({ DocId, UserId: user?._id }));
  };

  if (titlePerfectionLoading) {
    return (
      <Modal
        open={openTitlePerfectionModal}
        onClose={handleCloseTitlePerfectionModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={openTitlePerfectionModal}>
          <Box sx={modalStyle}>
            <Paper
              elevation={0}
              sx={{
                p: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "300px",
              }}>
              <CircularProgress size={60} sx={{ color: "#6d28d9", mb: 3 }} />
              <Typography
                variant="h6"
                sx={{ color: "#1e293b", fontWeight: 600 }}>
                Loading Title Perfection Data
              </Typography>
              <Typography variant="body2" sx={{ color: "#64748b", mt: 1 }}>
                Please wait while we fetch the pricing information...
              </Typography>
            </Paper>
          </Box>
        </Fade>
      </Modal>
    );
  }

  return (
    <Modal
      open={openTitlePerfectionModal}
      onClose={handleCloseTitlePerfectionModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={openTitlePerfectionModal}>
        <Box sx={modalStyle}>
          <Paper elevation={0}>
            {/* Header */}
            <Box
              sx={{
                p: 3,
                bgcolor: "#6d28d9",
                position: "relative",
              }}>
              <IconButton
                onClick={handleCloseTitlePerfectionModal}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "rgba(255, 255, 255, 0.8)",
                  "&:hover": {
                    bgcolor: "rgba(255, 255, 255, 0.1)",
                    color: "white",
                  },
                }}>
                <XIcon size={20} />
              </IconButton>

              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <CrownIcon size={28} color="white" />
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "white",
                      fontWeight: 600,
                      fontSize: "1.1rem",
                    }}>
                    Request Title Perfection
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{
                      color: "rgba(255, 255, 255, 0.8)",
                      mt: 0.5,
                      fontSize: "0.85rem",
                    }}>
                    Secure your property with official documentation
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* Content */}
            <Box
              sx={{ p: 3, maxHeight: "calc(90vh - 80px)", overflowY: "auto" }}>
              <Alert
                severity="info"
                sx={{
                  mb: 3,
                  "& .MuiAlert-icon": {
                    alignItems: "center",
                  },
                }}>
                <Typography variant="body2" sx={{ fontSize: "0.85rem" }}>
                  Title perfection is the final step in securing your property
                  rights and involves obtaining official government recognition
                  of your ownership.
                </Typography>
              </Alert>

              <Box sx={{ mb: 4 }}>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#1e293b",
                    fontWeight: 600,
                    mb: 2,
                    fontSize: "1rem",
                  }}>
                  Benefits of Title Perfection
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Card
                      variant="outlined"
                      sx={{ height: "100%", borderColor: "#e2e8f0" }}>
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            gap: 1.5,
                            mb: 1,
                          }}>
                          <CheckIcon size={18} color="#6d28d9" />
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 600,
                              color: "#1e293b",
                              fontSize: "0.95rem",
                            }}>
                            Legal Protection
                          </Typography>
                        </Box>
                        <Typography
                          variant="body2"
                          sx={{ color: "#475569", fontSize: "0.85rem" }}>
                          Provides full legal recognition and protection of your
                          property rights
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Card
                      variant="outlined"
                      sx={{ height: "100%", borderColor: "#e2e8f0" }}>
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            gap: 1.5,
                            mb: 1,
                          }}>
                          <CheckIcon size={18} color="#6d28d9" />
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 600,
                              color: "#1e293b",
                              fontSize: "0.95rem",
                            }}>
                            Increased Value
                          </Typography>
                        </Box>
                        <Typography
                          variant="body2"
                          sx={{ color: "#475569", fontSize: "0.85rem" }}>
                          Properties with perfected titles command higher market
                          values
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Card
                      variant="outlined"
                      sx={{ height: "100%", borderColor: "#e2e8f0" }}>
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            gap: 1.5,
                            mb: 1,
                          }}>
                          <CheckIcon size={18} color="#6d28d9" />
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 600,
                              color: "#1e293b",
                              fontSize: "0.95rem",
                            }}>
                            Easy Transfers
                          </Typography>
                        </Box>
                        <Typography
                          variant="body2"
                          sx={{ color: "#475569", fontSize: "0.85rem" }}>
                          Simplifies future property transfers and inheritance
                          processes
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Card
                      variant="outlined"
                      sx={{ height: "100%", borderColor: "#e2e8f0" }}>
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            gap: 1.5,
                            mb: 1,
                          }}>
                          <CheckIcon size={18} color="#6d28d9" />
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 600,
                              color: "#1e293b",
                              fontSize: "0.95rem",
                            }}>
                            Financial Access
                          </Typography>
                        </Box>
                        <Typography
                          variant="body2"
                          sx={{ color: "#475569", fontSize: "0.85rem" }}>
                          Makes it easier to use your property as collateral for
                          loans
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>

              <Divider sx={{ my: 3 }} />

              <Typography
                variant="h6"
                sx={{
                  color: "#1e293b",
                  fontWeight: 600,
                  mb: 2,
                  fontSize: "1rem",
                }}>
                Title Perfection Details for {State}
              </Typography>

              <TitlePerfectionDetails
                titlePerfectionData={singleTitlePerfection}
                propertyValue={propertyValue}
                state={State}
              />

              <Box sx={{ mt: 4, display: "flex", alignItems: "center", px: 1 }}>
                <HelpCircleIcon size={20} color="#64748b" className="mr-2" />
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.8rem",
                    color: "#64748b",
                    fontStyle: "italic",
                  }}>
                  By requesting title perfection, you'll receive a detailed
                  quotation based on your property's specific requirements.
                </Typography>
              </Box>

              <Button
                variant="contained"
                disabled={isLoading}
                onClick={handleSubmit}
                fullWidth
                sx={{
                  py: 1.5,
                  mt: 3,
                  backgroundColor: "#6d28d9",
                  color: "#fff",
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: "0.9rem",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(109, 40, 217, 0.2)",
                  "&:hover": {
                    backgroundColor: "#5b21b6",
                    transform: "translateY(-1px)",
                    boxShadow: "0 6px 8px rgba(109, 40, 217, 0.25)",
                  },
                }}>
                {isLoading ? (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <span className="animate-pulse mr-2">●</span>
                    <span>Processing...</span>
                  </Box>
                ) : (
                  "Request Title Perfection"
                )}
              </Button>
            </Box>
          </Paper>
        </Box>
      </Fade>
    </Modal>
  );
};

export default TitlePerfectionModal;
