import React from "react";
import DashboardNavbar from "../components/DashboardNavbar";
import { Box, Container, Grid, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Link } from "react-router-dom";
import UserDetails from "../components/userInformation/UserDetails";

const DashboardRealtor = () => {
  return (
    <>
      <DashboardNavbar />
      <Box component="div" sx={{ height: "90vh" }}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} md={3}>
              <UserDetails />
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <Box
                component="div"
                className="sm:p-5 p-5  bg-green-custom"
                sx={{ height: "90vh" }}>
                <Box component="div" className="p-3 mt-5 sm:mt-0  w-full">
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      opacity: "90%",
                    }}>
                    Dashboard
                  </Typography>
                  <Box className="grid sm:grid-cols-3 grid-cols-3 gap-4">
                    <Link to="cofoApplication">
                      <div
                        className={`border  rounded-lg h-20  p-2 text-center justify-center items-center flex space-x-2 mt-5  bg-gray-900 border-gray-900`}
                        style={{ border: "1px dotted gray" }}>
                        <span
                          style={{
                            fontSize: "11px",

                            fontWeight: "bold",
                            color: "#fff",
                          }}>
                          View Applied C of O
                        </span>{" "}
                        <AddCircleOutlineIcon
                          fontSize="small"
                          color="#fff"
                          className="text-white"
                        />
                      </div>
                    </Link>
                    <Link to="properties">
                      <div
                        className={`border  rounded-lg h-20  p-3 text-center justify-center items-center flex space-x-2 mt-5 bg-gray-300 border-gray-300`}
                        style={{ border: "1px dotted gray" }}>
                        <span
                          style={{
                            fontSize: "11px",

                            fontWeight: "bold",
                          }}>
                          Buildings for Sale
                        </span>{" "}
                        <AddCircleOutlineIcon fontSize="small" />
                      </div>
                    </Link>
                    <Link to="lands">
                      <div
                        className={`border  rounded-lg h-20  p-3 text-center justify-center items-center flex space-x-2 mt-5  bg-gray-300 border-gray-300`}
                        style={{ border: "1px dotted gray" }}>
                        <span
                          style={{
                            fontSize: "11px",

                            fontWeight: "bold",
                          }}>
                          Lands for Sale
                        </span>{" "}
                        <AddCircleOutlineIcon fontSize="small" />
                      </div>
                    </Link>
                    {/* <Link to="archivedLands">
                      <div
                        className={`border  rounded-lg h-20  p-3 text-center justify-center items-center flex space-x-2 mt-5  bg-teal-700 border-teal-700`}
                        style={{ border: "1px dotted gray" }}>
                        <span
                          style={{
                            fontSize: "11px",

                            fontWeight: "bold",
                            color: "#fff",
                          }}>
                          Expired Land Listing
                        </span>{" "}
                        <AddCircleOutlineIcon fontSize="small" />
                      </div>
                    </Link>
                    <Link to="archivedProperties">
                      <div
                        className={`border  rounded-lg h-20  p-3 text-center justify-center items-center flex space-x-2 mt-5  bg-teal-700 border-teal-700`}
                        style={{ border: "1px dotted gray" }}>
                        <span
                          style={{
                            fontSize: "11px",

                            fontWeight: "bold",
                            color: "#fff",
                          }}>
                          Expired Buildings Listing
                        </span>{" "}
                        <AddCircleOutlineIcon fontSize="small" />
                      </div>
                    </Link> */}
                    {/* <Link to="escrowedProperties">
                      <div
                        className={`border  rounded-lg h-20  p-2 text-center justify-center items-center flex space-x-2 mt-5  bg-blue-700 border-blue-700`}
                        style={{ border: "1px dotted gray" }}>
                        <span
                          style={{
                            fontSize: "11px",

                            fontWeight: "bold",
                            color: "#fff",
                          }}>
                          Escrowed Buildings
                        </span>{" "}
                        <AddCircleOutlineIcon fontSize="small" />
                      </div>
                    </Link>
                    <Link to="escrowedProperties">
                      <div
                        className={`border  rounded-lg h-20  p-2 text-center justify-center items-center flex space-x-2 mt-5  bg-blue-700 border-blue-700`}
                        style={{ border: "1px dotted gray" }}>
                        <span
                          style={{
                            fontSize: "11px",

                            fontWeight: "bold",
                            color: "#fff",
                          }}>
                          Escrowed Lands
                        </span>{" "}
                        <AddCircleOutlineIcon fontSize="small" />
                      </div>
                    </Link> */}
                    <Link to="verificationProperties">
                      <div
                        className={`border  rounded-lg h-20  p-2 text-center justify-center items-center flex space-x-2 mt-5  bg-orange-700 border-orange-700`}
                        style={{ border: "1px dotted gray" }}>
                        <span
                          style={{
                            fontSize: "11px",

                            fontWeight: "bold",
                            color: "#fff",
                          }}>
                          Buildings on Verification
                        </span>{" "}
                        <AddCircleOutlineIcon fontSize="small" />
                      </div>
                    </Link>
                    <Link to="verificationLands">
                      <div
                        className={`border  rounded-lg h-20  p-2 text-center justify-center items-center flex space-x-2 mt-5  bg-orange-700 border-orange-700`}
                        style={{ border: "1px dotted gray" }}>
                        <span
                          style={{
                            fontSize: "11px",

                            fontWeight: "bold",
                            color: "#fff",
                          }}>
                          Lands on Verification
                        </span>{" "}
                        <AddCircleOutlineIcon fontSize="small" />
                      </div>
                    </Link>
                    <Link to="drafts">
                      <div
                        className={`border  rounded-lg h-20  p-2 text-center justify-center items-center flex space-x-2 mt-5  bg-blue-700 border-blue-700`}
                        style={{ border: "1px dotted gray" }}>
                        <span
                          style={{
                            fontSize: "11px",

                            fontWeight: "bold",
                            color: "#fff",
                          }}>
                          Your Drafts
                        </span>{" "}
                        <AddCircleOutlineIcon fontSize="small" />
                      </div>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default DashboardRealtor;
