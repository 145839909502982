import React from "react";
import { Button, Grid, Box, Typography, Paper, Divider } from "@mui/material";
import PaymentModal from "../../components/addons/PaymentModal";
import { SearchIcon, FileTextIcon } from "lucide-react";

const InitiateDocumentSearchAndSurvey = ({
  isLoading,
  setOpenPaymentModal,
  handleOpenPaymentModal,
  handleInitializeSearchSurvey,
}) => {
  const INITIAL_PAYMENT_AMOUNT = 140000;

  const services = [
    { name: "CAC search", price: 30000 },
    { name: "Probate Registry search", price: 30000 },
    { name: "Land Registry search", price: 30000 },
    { name: "Initial Survey deposit", price: 50000 },
  ];

  return (
    <Paper
      elevation={0}
      sx={{
        padding: 3,
        borderRadius: 2,
        border: "1px solid #e2e8f0",
        background: "linear-gradient(145deg, #ffffff 0%, #f8fafc 100%)",
      }}>
      <Typography
        variant="h6"
        sx={{
          fontSize: "1rem",
          fontWeight: 700,
          mb: 2,
          color: "#1e293b",
        }}>
        Initial Payment Required
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontSize: "0.875rem",
          mb: 2,
          color: "#475569",
        }}>
        The following searches and a survey will be conducted:
      </Typography>

      <Box sx={{ mb: 3 }}>
        {services.map((service, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 1.5,
              borderBottom:
                index < services.length - 1 ? "1px dashed #e2e8f0" : "none",
            }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FileTextIcon size={16} color="#64748b" className="mr-2" />
              <Typography
                variant="body2"
                sx={{
                  fontSize: "0.875rem",
                  color: "#334155",
                }}>
                {service.name}
              </Typography>
            </Box>
            <Typography
              variant="body2"
              sx={{
                fontSize: "0.875rem",
                fontWeight: 600,
                color: "#0f172a",
              }}>
              ₦{service.price.toLocaleString()}
            </Typography>
          </Box>
        ))}
      </Box>

      <Divider sx={{ my: 2 }} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          py: 1.5,
          mb: 3,
        }}>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 600,
            color: "#0f172a",
          }}>
          Total
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 700,
            color: "#0f172a",
          }}>
          ₦{INITIAL_PAYMENT_AMOUNT.toLocaleString()}
        </Typography>
      </Box>

      <Typography
        variant="body2"
        sx={{
          fontSize: "0.875rem",
          mb: 3,
          p: 2,
          borderRadius: 1,
          backgroundColor: "#f8fafc",
          borderLeft: "3px solid #3b82f6",
          color: "#475569",
        }}>
        Please make the initial payment to begin the search and survey process.
        You'll gain access to all documentation upon completion.
      </Typography>

      <Button
        variant="contained"
        disableElevation
        disabled={isLoading}
        fullWidth
        startIcon={<SearchIcon size={18} />}
        onClick={() => setOpenPaymentModal(!handleOpenPaymentModal)}
        sx={{
          py: 1.5,
          backgroundColor: "#0f172a",
          color: "#fff",
          textTransform: "none",
          fontWeight: 600,
          fontSize: "0.9rem",
          borderRadius: "8px",
          boxShadow: "0 4px 6px rgba(15, 23, 42, 0.1)",
          "&:hover": {
            backgroundColor: "#1e293b",
            transform: "translateY(-1px)",
            boxShadow: "0 6px 8px rgba(15, 23, 42, 0.15)",
          },
        }}>
        {isLoading ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <span className="animate-pulse mr-2">●</span>
            <span>Processing...</span>
          </Box>
        ) : (
          "Proceed with Payment"
        )}
      </Button>

      <PaymentModal
        amount={INITIAL_PAYMENT_AMOUNT}
        handleOpenPaymentModal={handleOpenPaymentModal}
        setOpenPaymentModal={setOpenPaymentModal}
        handleInitializeSearchSurvey={handleInitializeSearchSurvey}
      />
    </Paper>
  );
};

export default InitiateDocumentSearchAndSurvey;
