import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
} from "@mui/material";

const CofOProgressTracker = ({ CofO }) => {
  const formatDate = (date) => {
    // Assuming you have a date formatting function
    return new Date(date).toLocaleDateString();
  };

  // Helper function to determine if a step is active based on the current status
  const isStepActive = (step) => {
    const statusOrder = ["pending", "awaiting", "under_review", "approved"];
    const currentStatusIndex = statusOrder.indexOf(CofO.status);
    const stepStatusIndex = statusOrder.indexOf(step);

    return stepStatusIndex <= currentStatusIndex;
  };

  return (
    <List dense disablePadding>
      {/* Step 1: Application Submitted */}
      <ListItem sx={{ px: 0 }}>
        <ListItemIcon sx={{ minWidth: 30 }}>
          <Box
            sx={{
              width: 20,
              height: 20,
              borderRadius: "50%",
              bgcolor: "#22c55e", // Always green
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Typography
              variant="body2"
              sx={{ color: "white", fontSize: "12px" }}>
              1
            </Typography>
          </Box>
        </ListItemIcon>
        <ListItemText
          primary="Application Submitted ( C of O verification ongoing )"
          secondary={formatDate(CofO.submissionDate)}
          primaryTypographyProps={{
            variant: "body2",
            fontWeight: "bold",
          }}
          secondaryTypographyProps={{ variant: "caption" }}
        />
      </ListItem>

      <Box sx={{ height: 30, ml: 2, borderLeft: "2px dashed #e0e0e0" }} />

      {/* Step 2: Awaiting Payment */}
      <ListItem sx={{ px: 0 }}>
        <ListItemIcon sx={{ minWidth: 30 }}>
          <Box
            sx={{
              width: 20,
              height: 20,
              borderRadius: "50%",
              bgcolor: isStepActive("awaiting") ? "#22c55e" : "#e0e0e0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Typography
              variant="body2"
              sx={{
                color: isStepActive("awaiting") ? "white" : "#757575",
                fontSize: "12px",
              }}>
              2
            </Typography>
          </Box>
        </ListItemIcon>
        <ListItemText
          primary="Awaiting Payment"
          primaryTypographyProps={{
            variant: "body2",
            fontWeight: isStepActive("awaiting") ? "bold" : "normal",
            color: isStepActive("awaiting") ? "textPrimary" : "text.secondary",
          }}
        />
      </ListItem>

      <Box sx={{ height: 30, ml: 2, borderLeft: "2px dashed #e0e0e0" }} />

      {/* Step 3: Review In Progress */}
      <ListItem sx={{ px: 0 }}>
        <ListItemIcon sx={{ minWidth: 30 }}>
          <Box
            sx={{
              width: 20,
              height: 20,
              borderRadius: "50%",
              bgcolor: isStepActive("under_review") ? "#22c55e" : "#e0e0e0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Typography
              variant="body2"
              sx={{
                color: isStepActive("under_review") ? "white" : "#757575",
                fontSize: "12px",
              }}>
              3
            </Typography>
          </Box>
        </ListItemIcon>
        <ListItemText
          primary="Review In Progress"
          primaryTypographyProps={{
            variant: "body2",
            fontWeight: isStepActive("under_review") ? "bold" : "normal",
            color: isStepActive("under_review")
              ? "textPrimary"
              : "text.secondary",
          }}
        />
      </ListItem>

      <Box sx={{ height: 30, ml: 2, borderLeft: "2px dashed #e0e0e0" }} />

      {/* Step 4: Application Approved */}
      <ListItem sx={{ px: 0 }}>
        <ListItemIcon sx={{ minWidth: 30 }}>
          <Box
            sx={{
              width: 20,
              height: 20,
              borderRadius: "50%",
              bgcolor: isStepActive("approved") ? "#22c55e" : "#e0e0e0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Typography
              variant="body2"
              sx={{
                color: isStepActive("approved") ? "white" : "#757575",
                fontSize: "12px",
              }}>
              4
            </Typography>
          </Box>
        </ListItemIcon>
        <ListItemText
          primary="Application Approved"
          primaryTypographyProps={{
            variant: "body2",
            fontWeight: isStepActive("approved") ? "bold" : "normal",
            color: isStepActive("approved") ? "textPrimary" : "text.secondary",
          }}
        />
      </ListItem>

      <Box sx={{ height: 30, ml: 2, borderLeft: "2px dashed #e0e0e0" }} />

      {/* Step 5: C of O Issued - Always green when status is approved */}
      <ListItem sx={{ px: 0 }}>
        <ListItemIcon sx={{ minWidth: 30 }}>
          <Box
            sx={{
              width: 20,
              height: 20,
              borderRadius: "50%",
              bgcolor: CofO.status === "approved" ? "#22c55e" : "#e0e0e0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Typography
              variant="body2"
              sx={{
                color: CofO.status === "approved" ? "white" : "#757575",
                fontSize: "12px",
              }}>
              5
            </Typography>
          </Box>
        </ListItemIcon>
        <ListItemText
          primary="C of O Issued"
          primaryTypographyProps={{
            variant: "body2",
            fontWeight: CofO.status === "approved" ? "bold" : "normal",
            color:
              CofO.status === "approved" ? "textPrimary" : "text.secondary",
          }}
        />
      </ListItem>
    </List>
  );
};

export default CofOProgressTracker;
