import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import useHandleInitializeSearch from "../../hooks/useHandleInitializeSearch";
import DisplaySearchAndSurveyDocument from "../Land_Property_Sidebar_Actions/DisplaySearchAndSurveyDocument";
import EscrowFullPayment from "../Land_Property_Sidebar_Actions/EscrowFullPayment";
import EscrowHalfPayment from "../Land_Property_Sidebar_Actions/EscrowHalfPayment";
import InitiateDocumentSearchAndSurvey from "../Land_Property_Sidebar_Actions/InitiateDocumentSearchAndSurvey";
import InitiateFinalPayment from "../Land_Property_Sidebar_Actions/InitiateFinalPayment";
import DeedOfSaleModal from "../../components/addons/DeedOfSaleModal";
import TitlePerfectionModal from "../../components/addons/TitlePerfectionModal";
import InitiateTitlePerfectionPayment from "../Land_Property_Sidebar_Actions/InitiateTitlePerfectionPayment";
import { Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "lucide-react";

const LandInfoSideBar = ({ land, user }) => {
  const {
    handleOpenPaymentModal,
    handleInitializeSearchSurvey,
    handleFinalPaymentVerification,
    isLoading,
    setOpenPaymentModal,
    openDeedOfSaleModal,
    handleOpenDeedOfSaleModal,
    handleCloseDeedOfSaleModal,
    openTitlePerfectionModal,
    handleOpenTitlePerfectionModal,
    handleCloseTitlePerfectionModal,
  } = useHandleInitializeSearch({ propertyType: "land" });

  const showSidebar =
    (land?.lands?.UserId === user?._id && land?.lands?.Listings === false) ||
    land?.lands?.UserId !== user?._id;

  // Check if deed of sale or title perfection exists
  const hasDeedOfSale = land?.document?.FinalDeedOfSaleStatus === true;
  const hasTitlePerfection =
    land?.document?.TitlePerfectionFinalDocumentStatus === true;
  const showCofOButton = hasDeedOfSale || hasTitlePerfection;

  return (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <Box
        component="div"
        className="rounded-lg p-4 mt-1 shadow-sm"
        sx={{
          height: "78vh",
          overflowY: "auto",
          background: "linear-gradient(145deg, #f8f9fa 0%, #edf2f7 100%)",
          border: "1px solid #e2e8f0",
        }}>
        {showSidebar ? (
          <div className="my-2">
            {/* C of O Application Button */}
            {showCofOButton && (
              <Link
                to="/cofoApplication?type=existing-user"
                style={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  disableElevation
                  size="medium"
                  endIcon={<ArrowRightIcon size={18} />}
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    fontWeight: 600,
                    fontSize: "0.9rem",
                    backgroundColor: "#0284c7",
                    padding: "12px 16px",
                    marginBottom: 3,
                    boxShadow: "0 4px 6px rgba(2, 132, 199, 0.2)",
                    "&:hover": {
                      backgroundColor: "#0369a1",
                      transform: "translateY(-1px)",
                      boxShadow: "0 6px 8px rgba(2, 132, 199, 0.25)",
                    },
                  }}
                  className="text-white">
                  Apply for Certificate of Occupancy
                </Button>
              </Link>
            )}

            {/* Escrow Payment Components */}
            {land?.document?.AgentConfirmedPrice !== 0 && (
              <>
                <EscrowHalfPayment property={land} />
                <EscrowFullPayment property={land} />
              </>
            )}

            {/* Title Perfection Section */}
            {/* Request Title Perfection */}
            {land?.document?.FinalDeedOfSaleStatus === true &&
              land?.document?.TitlePerfectionRequestStatus ===
                "Not Requested" && (
                <>
                  <TitlePerfectionModal
                    openTitlePerfectionModal={openTitlePerfectionModal}
                    handleCloseTitlePerfectionModal={
                      handleCloseTitlePerfectionModal
                    }
                    propertyValue={land?.document?.AgentConfirmedPrice}
                    State={land?.lands?.State}
                    DocId={land?.document?._id}
                  />
                  <Button
                    variant="contained"
                    onClick={handleOpenTitlePerfectionModal}
                    disableElevation
                    disabled={isLoading}
                    size="medium"
                    sx={{
                      width: "100%",
                      textTransform: "none",
                      fontWeight: 500,
                      fontSize: "0.85rem",
                      backgroundColor: "#6b46c1",
                      padding: "10px 0",
                      marginTop: 2,
                      marginBottom: 2,
                      boxShadow: "0 4px 6px rgba(107, 70, 193, 0.2)",
                      "&:hover": {
                        backgroundColor: "#553c9a",
                      },
                    }}
                    className="text-white">
                    {isLoading ? "Please wait..." : "Request Title Perfection"}
                  </Button>
                </>
              )}

            {/* Awaiting Title perfection request notification */}
            {land?.document?.TitlePerfectionRequestStatus === "Pending" && (
              <div className="bg-green-100 text-green-800 p-4 my-3 rounded-lg text-center border border-green-200 shadow-sm">
                <h5 className="text-xs font-medium uppercase tracking-wide mb-1">
                  Title Perfection Requested
                </h5>
                <div className="text-sm font-bold">
                  Awaiting Price Confirmation
                </div>
              </div>
            )}

            {/* Awaiting Title perfection document notification, after payment */}
            {land?.document?.TitlePerfectionPaymentStatus === true &&
              land?.document?.TitlePerfectionFinalDocumentStatus === false && (
                <div className="bg-green-100 text-green-800 p-4 my-3 rounded-lg text-center border border-green-200 shadow-sm">
                  <h5 className="text-xs font-medium uppercase tracking-wide mb-1">
                    Payment Complete
                  </h5>
                  <div className="text-sm font-bold">
                    Awaiting Final Document
                  </div>
                </div>
              )}

            {/*   Title perfection notification */}
            {land?.document?.TitlePerfectionFinalDocumentStatus && (
              <div className="bg-white text-green-800 p-4 my-3 rounded-lg text-center border border-green-200 shadow-sm">
                <h5 className="text-xs font-medium uppercase tracking-wide mb-2">
                  Title Perfection Complete
                </h5>
                <a
                  target="_blank"
                  href={`${land?.document?.TitlePerfectionFinalDocument}`}>
                  <Box className="rounded-lg border-0 py-3 px-4 mt-2 text-white cursor-pointer bg-gradient-to-r from-green-700 to-indigo-800 hover:from-green-800 hover:to-indigo-900 transition-all duration-200 shadow-md">
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "0.85rem",
                      }}>
                      View Title Perfection Document
                    </Typography>
                  </Box>
                </a>
              </div>
            )}

            {/* Payment for Title perfection notification */}
            {land?.document?.TitlePerfectionRequestStatus === "Approved" &&
              land?.document?.TitlePerfectionPaymentStatus === false && (
                <div>
                  <div className="bg-green-100 text-green-800 p-4 my-3 rounded-lg text-center border border-green-200 shadow-sm">
                    <a
                      target="_blank"
                      href={`${land?.document?.TitlePerfectionPricingDocument}`}>
                      <h5 className="text-sm font-semibold hover:underline">
                        View Title Perfection Pricing Document
                      </h5>
                    </a>
                  </div>
                  <InitiateTitlePerfectionPayment property={land} />
                </div>
              )}

            {/* Deed of sale Section */}
            {land?.document?.FullPropertyPaymentStatus === true &&
              land?.document?.AgentDeedOfSaleStatus === true &&
              land?.document?.BuyersDeedOfSaleStatus === false && (
                <>
                  <DeedOfSaleModal
                    openDeedOfSaleModal={openDeedOfSaleModal}
                    handleCloseDeedOfSaleModal={handleCloseDeedOfSaleModal}
                    Docid={land?.document?._id}
                  />
                  <a
                    target="_blank"
                    href={`${land?.document?.DraftDeedOfSale}`}>
                    <Box className="rounded-lg border-0 py-3 px-4 my-3 text-green-900 cursor-pointer bg-gradient-to-r from-green-50 to-teal-50 hover:from-green-100 hover:to-teal-100 transition-all duration-200 shadow-sm border border-green-200">
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "0.85rem",
                          textAlign: "center",
                        }}>
                        Download Draft Deed of Sale
                      </Typography>
                    </Box>
                  </a>
                  <Button
                    variant="contained"
                    onClick={handleOpenDeedOfSaleModal}
                    disableElevation
                    disabled={isLoading}
                    size="medium"
                    sx={{
                      width: "100%",
                      textTransform: "none",
                      fontWeight: 500,
                      fontSize: "0.85rem",
                      backgroundColor: "#047857",
                      padding: "10px 0",
                      marginBottom: 2,
                      boxShadow: "0 4px 6px rgba(4, 120, 87, 0.2)",
                      "&:hover": {
                        backgroundColor: "#065f46",
                      },
                    }}
                    className="text-white">
                    {isLoading
                      ? "Please wait..."
                      : "Upload Signed Deed Of Sale"}
                  </Button>
                </>
              )}

            {/* Awaiting Deed of sale notification */}
            {land?.document?.FullPropertyPaymentStatus === true &&
              land?.document?.AgentDeedOfSaleStatus === true &&
              land?.document?.BuyersDeedOfSaleStatus === true &&
              land?.document?.FinalDeedOfSaleStatus === false && (
                <div className="bg-green-50 text-green-800 p-4 my-3 rounded-lg text-center border border-green-200 shadow-sm">
                  <h5 className="text-xs font-medium uppercase tracking-wide mb-1">
                    Draft Deed of Sale Uploaded
                  </h5>
                  <div className="text-sm font-bold">
                    Awaiting Final Deed of Sale
                  </div>
                </div>
              )}

            {/* Final Deed of sale notification */}
            {land?.document?.FullPropertyPaymentStatus === true &&
              land?.document?.AgentDeedOfSaleStatus === true &&
              land?.document?.BuyersDeedOfSaleStatus === true &&
              land?.document?.FinalDeedOfSaleStatus === true && (
                <div className="bg-white text-green-800 p-4 my-3 rounded-lg text-center border border-green-200 shadow-sm">
                  <h5 className="text-xs font-medium uppercase tracking-wide mb-2">
                    Final Deed of Sale Available
                  </h5>
                  <a
                    target="_blank"
                    href={`${land?.document?.FinalDeedOfSale}`}>
                    <Box className="rounded-lg border-0 py-3 px-4 mt-2 text-white cursor-pointer bg-gradient-to-r from-green-600 to-teal-600 hover:from-green-700 hover:to-teal-700 transition-all duration-200 shadow-md">
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "0.85rem",
                        }}>
                        View Deed of Sale Document
                      </Typography>
                    </Box>
                  </a>
                </div>
              )}

            {/* Document Validation Section */}
            <div className="mt-5">
              <Stack spacing={2} direction="column">
                <>
                  {(land?.document?.SurveyStatus === "pending" &&
                    land?.document?.SurveyMessageStatus === false) ||
                  (land?.document?.Status === "pending" &&
                    land?.document?.ValidationMessageStatus === false) ? (
                    <>
                      <Typography
                        sx={{
                          fontSize: "0.85rem",
                          fontWeight: 600,
                          backgroundColor: "#f0f9ff",
                          padding: "12px",
                          borderRadius: "8px",
                          borderLeft: "4px solid #0ea5e9",
                          marginBottom: "12px",
                        }}>
                        We're actively conducting searches and survey for this
                        property. Thank you for your patience; we'll keep you
                        updated.
                      </Typography>

                      <Button
                        variant="contained"
                        disableElevation
                        disabled
                        size="medium"
                        sx={{
                          fontSize: "0.85rem",
                          width: "100%",
                          textTransform: "none",
                          backgroundColor: "#0ea5e9",
                          fontWeight: 500,
                          "&.Mui-disabled": {
                            backgroundColor: "#bae6fd",
                            color: "#0c4a6e",
                          },
                        }}>
                        Search/Survey in Progress
                      </Button>
                    </>
                  ) : (land?.document?.SurveyStatus === "completed" ||
                      land?.document?.Status === "completed") &&
                    land?.document?.UnbalancedPaymentStatus === true ? (
                    <DisplaySearchAndSurveyDocument land={land} />
                  ) : (land?.document?.SurveyStatus === "completed" ||
                      land?.document?.Status === "completed") &&
                    land?.document?.UnbalancedPaymentStatus === false ? (
                    <InitiateFinalPayment
                      isLoading={isLoading}
                      handleOpenPaymentModal={handleOpenPaymentModal}
                      handleFinalPaymentVerification={
                        handleFinalPaymentVerification
                      }
                      setOpenPaymentModal={setOpenPaymentModal}
                      document={land?.document}
                    />
                  ) : (
                    <InitiateDocumentSearchAndSurvey
                      isLoading={isLoading}
                      handleOpenPaymentModal={handleOpenPaymentModal}
                      handleInitializeSearchSurvey={
                        handleInitializeSearchSurvey
                      }
                      setOpenPaymentModal={setOpenPaymentModal}
                    />
                  )}
                </>
              </Stack>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center h-full">
            <Typography sx={{ color: "#64748b", fontStyle: "italic" }}>
              No actions available for this property
            </Typography>
          </div>
        )}
      </Box>
    </Grid>
  );
};

export default LandInfoSideBar;
