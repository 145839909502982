import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import completeSvg from "../../assets/completeSVG.svg";
import searchSvg from "../../assets/search.svg";
import useHandleInitializeSearch from "../../hooks/useHandleInitializeSearch";
import DisplaySearchAndSurveyDocumentForProperties from "../Land_Property_Sidebar_Actions/DisplaySearchAndSurveyDocumentForProperties";
import EscrowFullPayment from "../Land_Property_Sidebar_Actions/EscrowFullPayment";
import EscrowHalfPayment from "../Land_Property_Sidebar_Actions/EscrowHalfPayment";
import InitiateDocumentSearchAndSurvey from "../Land_Property_Sidebar_Actions/InitiateDocumentSearchAndSurvey";
import InitiateFinalPayment from "../Land_Property_Sidebar_Actions/InitiateFinalPayment";
import DeedOfSaleModal from "../addons/DeedOfSaleModal";
import TitlePerfectionModal from "../addons/TitlePerfectionModal";
import InitiateTitlePerfectionPayment from "../Land_Property_Sidebar_Actions/InitiateTitlePerfectionPayment";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "lucide-react";

const PropertyInfoSideBar = ({ property, user }) => {
  const {
    handleOpenPaymentModal,
    handleInitializeSearchSurvey,
    handleFinalPaymentVerification,
    isLoading,
    setOpenPaymentModal,
    openDeedOfSaleModal,
    handleOpenDeedOfSaleModal,
    handleCloseDeedOfSaleModal,
    openTitlePerfectionModal,
    handleOpenTitlePerfectionModal,
    handleCloseTitlePerfectionModal,
  } = useHandleInitializeSearch({ propertyType: "property" });

  // Check if deed of sale or title perfection exists
  const hasDeedOfSale = property?.document?.FinalDeedOfSaleStatus === true;
  const hasTitlePerfection =
    property?.document?.TitlePerfectionFinalDocumentStatus === true;
  const showCofOButton = hasDeedOfSale || hasTitlePerfection;

  return (
    <Grid item xs={12} sm={12} md={12} lg={5}>
      <Box
        component="div"
        className="rounded-lg p-4 mt-1 shadow-sm"
        sx={{
          height: "78vh",
          overflowY: "auto",
          background: "linear-gradient(145deg, #f8f9fa 0%, #edf2f7 100%)",
          border: "1px solid #e2e8f0",
        }}>
        {property?.properties?.UserId !== user?._id ||
        (property?.properties?.UserId === user?._id &&
          property?.properties?.Listings === false) ? (
          <div className="my-2">
            {/* C of O Application Button */}
            {showCofOButton && (
              <Link
                to="/cofoapplication?type=existing"
                style={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  disableElevation
                  size="medium"
                  endIcon={<ArrowRightIcon size={18} />}
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    fontWeight: 600,
                    fontSize: "0.9rem",
                    backgroundColor: "#0284c7",
                    padding: "12px 16px",
                    marginBottom: 3,
                    boxShadow: "0 4px 6px rgba(2, 132, 199, 0.2)",
                    "&:hover": {
                      backgroundColor: "#0369a1",
                      transform: "translateY(-1px)",
                      boxShadow: "0 6px 8px rgba(2, 132, 199, 0.25)",
                    },
                  }}
                  className="text-white">
                  Apply for Certificate of Occupancy
                </Button>
              </Link>
            )}

            <div className="">
              {/* Escrow Piece */}
              {property?.document?.AgentConfirmedPrice !== 0 && (
                <>
                  <EscrowHalfPayment property={property} />
                  <EscrowFullPayment property={property} />
                </>
              )}

              {/* Title Perfection  */}
              {property?.document?.FinalDeedOfSaleStatus === true &&
                property?.document?.TitlePerfectionRequestStatus ===
                  "Not Requested" && (
                  <>
                    <TitlePerfectionModal
                      openTitlePerfectionModal={openTitlePerfectionModal}
                      handleCloseTitlePerfectionModal={
                        handleCloseTitlePerfectionModal
                      }
                      propertyValue={property?.document?.AgentConfirmedPrice}
                      State={property?.properties?.State}
                      DocId={property?.document?._id}
                    />
                    <Button
                      variant="contained"
                      onClick={handleOpenTitlePerfectionModal}
                      disableElevation
                      disabled={isLoading}
                      size="medium"
                      sx={{
                        width: "100%",
                        textTransform: "none",
                        fontWeight: 500,
                        fontSize: "0.85rem",
                        backgroundColor: "#6b46c1",
                        padding: "10px 0",
                        marginTop: 2,
                        marginBottom: 2,
                        boxShadow: "0 4px 6px rgba(107, 70, 193, 0.2)",
                        "&:hover": {
                          backgroundColor: "#553c9a",
                        },
                      }}
                      className="text-white">
                      {isLoading
                        ? "Please wait..."
                        : "Request Title Perfection"}
                    </Button>
                  </>
                )}

              {/* Awaiting Title perfection request notification */}
              {property?.document?.TitlePerfectionRequestStatus ===
                "Pending" && (
                <div className="bg-green-100 text-green-800 p-4 my-3 rounded-lg text-center border border-green-200 shadow-sm">
                  <h5 className="text-xs font-medium uppercase tracking-wide mb-1">
                    Title Perfection Requested
                  </h5>
                  <div className="text-sm font-bold">
                    Awaiting Price Confirmation
                  </div>
                </div>
              )}

              {/* Awaiting Title perfection document notification, after payment */}
              {property?.document?.TitlePerfectionPaymentStatus === true &&
                property?.document?.TitlePerfectionFinalDocumentStatus ===
                  false && (
                  <div className="bg-green-100 text-green-800 p-4 my-3 rounded-lg text-center border border-green-200 shadow-sm">
                    <h5 className="text-xs font-medium uppercase tracking-wide mb-1">
                      Payment Complete
                    </h5>
                    <div className="text-sm font-bold">Awaiting Document</div>
                  </div>
                )}

              {/*   Title perfection notification */}
              {property?.document?.TitlePerfectionFinalDocumentStatus && (
                <div className="bg-white text-green-800 p-4 my-3 rounded-lg text-center border border-green-200 shadow-sm">
                  <h5 className="text-xs font-medium uppercase tracking-wide mb-2">
                    Title Perfection Complete
                  </h5>
                  <a
                    target="_blank"
                    href={`${property?.document?.TitlePerfectionFinalDocument}`}>
                    <Box className="rounded-lg border-0 py-3 px-4 mt-2 text-white cursor-pointer bg-gradient-to-r from-green-700 to-indigo-800 hover:from-green-800 hover:to-indigo-900 transition-all duration-200 shadow-md">
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "0.85rem",
                        }}>
                        View Title Perfection Document
                      </Typography>
                    </Box>
                  </a>
                </div>
              )}

              {/* Payment for  Title perfection notification */}
              {property?.document?.TitlePerfectionRequestStatus ===
                "Approved" &&
                property?.document?.TitlePerfectionPaymentStatus === false && (
                  <div>
                    <div className="bg-green-100 text-green-800 p-4 my-3 rounded-lg text-center border border-green-200 shadow-sm">
                      <a
                        target="_blank"
                        href={`${property?.document?.TitlePerfectionPricingDocument}`}>
                        <h5 className="text-sm font-semibold hover:underline">
                          View Title Perfection Pricing Document
                        </h5>
                      </a>
                    </div>
                    <InitiateTitlePerfectionPayment property={property} />
                  </div>
                )}

              {/* Deed of sale Piece */}
              {property?.document?.FullPropertyPaymentStatus === true &&
                property?.document?.AgentDeedOfSaleStatus === true &&
                property?.document?.BuyersDeedOfSaleStatus === false && (
                  <>
                    <DeedOfSaleModal
                      openDeedOfSaleModal={openDeedOfSaleModal}
                      handleCloseDeedOfSaleModal={handleCloseDeedOfSaleModal}
                      Docid={property?.document?._id}
                    />
                    <a
                      target="_blank"
                      href={`${property?.document?.DraftDeedOfSale}`}>
                      <Box className="rounded-lg border-0 py-3 px-4 my-3 text-green-900 cursor-pointer bg-gradient-to-r from-green-50 to-teal-50 hover:from-green-100 hover:to-teal-100 transition-all duration-200 shadow-sm border border-green-200">
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "0.85rem",
                            textAlign: "center",
                          }}>
                          Download Draft Deed of Sale
                        </Typography>
                      </Box>
                    </a>
                    <Button
                      variant="contained"
                      onClick={handleOpenDeedOfSaleModal}
                      disableElevation
                      disabled={isLoading}
                      size="medium"
                      sx={{
                        width: "100%",
                        textTransform: "none",
                        fontWeight: 500,
                        fontSize: "0.85rem",
                        backgroundColor: "#047857",
                        padding: "10px 0",
                        marginBottom: 2,
                        boxShadow: "0 4px 6px rgba(4, 120, 87, 0.2)",
                        "&:hover": {
                          backgroundColor: "#065f46",
                        },
                      }}
                      className="text-white">
                      {isLoading
                        ? "Please wait..."
                        : "Upload Signed Deed Of Sale"}
                    </Button>
                  </>
                )}

              {/* Awaiting Deed of sale notification */}
              {property?.document?.FullPropertyPaymentStatus === true &&
                property?.document?.AgentDeedOfSaleStatus === true &&
                property?.document?.BuyersDeedOfSaleStatus === true &&
                property?.document?.FinalDeedOfSaleStatus === false && (
                  <div className="bg-green-50 text-green-800 p-4 my-3 rounded-lg text-center border border-green-200 shadow-sm">
                    <h5 className="text-xs font-medium uppercase tracking-wide mb-1">
                      Draft Deed of Sale Uploaded
                    </h5>
                    <div className="text-sm font-bold">
                      Awaiting Final Deed of Sale
                    </div>
                  </div>
                )}

              {/* Final Deed of sale notification */}
              {property?.document?.FullPropertyPaymentStatus === true &&
                property?.document?.AgentDeedOfSaleStatus === true &&
                property?.document?.BuyersDeedOfSaleStatus === true &&
                property?.document?.FinalDeedOfSaleStatus === true && (
                  <div className="bg-white text-green-800 p-4 my-3 rounded-lg text-center border border-green-200 shadow-sm">
                    <h5 className="text-xs font-medium uppercase tracking-wide mb-2">
                      Final Deed of Sale Available
                    </h5>
                    <a
                      target="_blank"
                      href={`${property?.document?.FinalDeedOfSale}`}>
                      <Box className="rounded-lg border-0 py-3 px-4 mt-2 text-white cursor-pointer bg-gradient-to-r from-green-600 to-teal-600 hover:from-green-700 hover:to-teal-700 transition-all duration-200 shadow-md">
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "0.85rem",
                          }}>
                          View Deed of Sale Document
                        </Typography>
                      </Box>
                    </a>
                  </div>
                )}

              <div className="mt-5">
                <Stack spacing={2} direction="column">
                  <>
                    {(property?.document?.SurveyStatus === "pending" &&
                      property?.document?.SurveyMessageStatus === false) ||
                    (property?.document?.Status === "pending" &&
                      property?.document?.ValidationMessageStatus === false) ? (
                      <>
                        <Typography
                          sx={{
                            fontSize: "0.85rem",
                            fontWeight: 600,
                            backgroundColor: "#f0f9ff",
                            padding: "12px",
                            borderRadius: "8px",
                            borderLeft: "4px solid #0ea5e9",
                            marginBottom: "12px",
                          }}>
                          We're actively conducting searches and survey for this
                          property. Thank you for your patience; we'll keep you
                          updated.
                        </Typography>

                        <Button
                          variant="contained"
                          disableElevation
                          disabled
                          size="medium"
                          sx={{
                            fontSize: "0.85rem",
                            width: "100%",
                            textTransform: "none",
                            backgroundColor: "#0ea5e9",
                            fontWeight: 500,
                            "&.Mui-disabled": {
                              backgroundColor: "#bae6fd",
                              color: "#0c4a6e",
                            },
                          }}>
                          Search/Survey in Progress
                        </Button>
                      </>
                    ) : (property?.document?.SurveyStatus === "completed" ||
                        property?.document?.Status === "completed") &&
                      property?.document?.UnbalancedPaymentStatus === true ? (
                      <DisplaySearchAndSurveyDocumentForProperties
                        property={property}
                      />
                    ) : (property?.document?.SurveyStatus === "completed" ||
                        property?.document?.Status === "completed") &&
                      property?.document?.UnbalancedPaymentStatus === false ? (
                      <InitiateFinalPayment
                        isLoading={isLoading}
                        handleOpenPaymentModal={handleOpenPaymentModal}
                        handleFinalPaymentVerification={
                          handleFinalPaymentVerification
                        }
                        setOpenPaymentModal={setOpenPaymentModal}
                        document={property?.document}
                      />
                    ) : (
                      <InitiateDocumentSearchAndSurvey
                        isLoading={isLoading}
                        handleOpenPaymentModal={handleOpenPaymentModal}
                        handleInitializeSearchSurvey={
                          handleInitializeSearchSurvey
                        }
                        setOpenPaymentModal={setOpenPaymentModal}
                      />
                    )}
                  </>
                </Stack>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center h-full">
            <Typography sx={{ color: "#64748b", fontStyle: "italic" }}>
              No actions available for this property
            </Typography>
          </div>
        )}
      </Box>
    </Grid>
  );
};

export default PropertyInfoSideBar;
