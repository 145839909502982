import React from "react";
import { Button, Typography, Box } from "@mui/material";
import useHandleEscrowPayment from "../../hooks/useHandleEscrowPayment";
import PaymentModal from "../../components/addons/PaymentModal";
import { LockIcon } from "lucide-react";

const EscrowHalfPayment = ({ property }) => {
  const {
    handleOpenPaymentModal,
    handleEscrowPayment,
    isLoading,
    setOpenPaymentModal,
  } = useHandleEscrowPayment({ docId: property?.document?._id });

  const escrowAmount = property?.document?.AgentConfirmedEscrowPrice;
  const escrowCompleted = property?.document?.EscrowStatus === true;
  const canMakeEscrowPayment =
    property?.document?.FullPropertyPaymentStatus === false;

  if (!escrowAmount) {
    return null;
  }

  return (
    <>
      {escrowCompleted ? (
        <Box
          className="my-3 rounded-lg overflow-hidden border border-green-200"
          sx={{
            background: "linear-gradient(145deg, #f0fdf4 0%, #dcfce7 100%)",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.04)",
          }}>
          <Box
            className="bg-green-600 text-white py-1 px-3"
            sx={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}>
            <Typography
              variant="caption"
              sx={{
                fontSize: "0.65rem",
                fontWeight: 600,
                textTransform: "uppercase",
                letterSpacing: "0.05em",
              }}>
              Escrow Payment Complete
            </Typography>
          </Box>
          <Box className="p-3 text-center">
            <Typography
              variant="h6"
              sx={{
                fontWeight: 700,
                color: "#166534",
                fontSize: "1.1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
              }}>
              ₦{escrowAmount?.toLocaleString()}
            </Typography>
          </Box>
        </Box>
      ) : (
        <>
          {canMakeEscrowPayment && (
            <Button
              variant="contained"
              onClick={() => setOpenPaymentModal(true)}
              disabled={isLoading}
              startIcon={<LockIcon size={16} />}
              sx={{
                width: "100%",
                padding: "12px 20px",
                marginY: 2,
                borderRadius: "8px",
                textTransform: "none",
                backgroundColor: "#10b981",
                fontWeight: 600,
                fontSize: "0.9rem",
                boxShadow: "0 4px 6px rgba(16, 185, 129, 0.2)",
                transition: "all 0.2s ease",
                "&:hover": {
                  backgroundColor: "#059669",
                  transform: "translateY(-1px)",
                  boxShadow: "0 6px 8px rgba(16, 185, 129, 0.25)",
                },
                "& .MuiButton-startIcon": {
                  marginRight: "8px",
                },
              }}>
              {isLoading ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <span className="animate-pulse mr-2">●</span>
                  <span>Processing...</span>
                </Box>
              ) : (
                "Make Escrow Payment"
              )}
            </Button>
          )}
        </>
      )}

      <PaymentModal
        amount={escrowAmount}
        handleOpenPaymentModal={handleOpenPaymentModal}
        setOpenPaymentModal={setOpenPaymentModal}
        handleEscrowPayment={handleEscrowPayment}
        escrowPaymentType="initial"
      />
    </>
  );
};

export default EscrowHalfPayment;
