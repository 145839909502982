import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Box, Container, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../components/addons/Loader";
import NotFound from "../components/addons/NotFound";
import Pagination from "../components/addons/Pagination";
import DashboardNavbar from "../components/DashboardNavbar";
import UserDetails from "../components/userInformation/UserDetails";
import { getLandsByUser, reset } from "../features/land/landSlice";

const DashboardRealtor = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(4);
  const [searchTerm, setSearchTerm] = useState("");
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  const {
    landsByUser: properties,
    isLoading,
    isSuccess,
    isError,
    message,
  } = useSelector((state) => state.land);

  useEffect(() => {
    dispatch(getLandsByUser());
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        onClose: () => {
          dispatch(reset());
        },
      });
    }

    dispatch(reset());
  }, [isError, isSuccess]);

  let items = [];

  properties?.map((property) => {
    items.push({
      Image: property.Images[0],
      Address: property.Address,
      UniqueId: property.UniqueId,
      Price: property.Price,
      Currency: property.Currency,
      Size: property.Size,
      CreatedAt: property.CreatedAt,
      id: property._id,
    });
  });

  var items2;

  items2 = items?.filter(
    (property) =>
      property?.Address.toLowerCase().includes(searchTerm.toLowerCase()) ||
      property?.UniqueId?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const paginatedItems = items2?.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <>
      <DashboardNavbar />
      <Box component="div" sx={{ height: "90vh" }}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} md={3}>
              <UserDetails />
            </Grid>
            <Grid item xs={12} sm={6} md={9}>
              <Box
                component="div"
                className="sm:p-5 p-5  bg-green-custom"
                sx={{ height: "90vh" }}>
                <Box component="div" className="p-3 mt-3 sm:mt-0  w-full">
                  <Link to="/dashboard">
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        opacity: "90%",
                      }}>
                      Go Back
                    </Typography>
                  </Link>
                  <Box component="div" className=" p-2">
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}>
                      Lands For Sale
                    </Typography>
                    {properties?.length > 0 && (
                      <Box className="my-3">
                        <TextField
                          id="standard-search"
                          label="Search field"
                          type="search"
                          sx={{ fontSize: "12px" }}
                          variant="standard"
                          placeholder="Enter Land ID"
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </Box>
                    )}

                    {isLoading ? (
                      <Loader />
                    ) : !isLoading && isError ? (
                      <NotFound />
                    ) : (
                      <Box>
                        {properties?.length == 0 ? (
                          <NotFound />
                        ) : (
                          <Grid container spacing={2}>
                            {paginatedItems?.map((item, index) => {
                              return (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={6}
                                  className=" flex flex-col items-center justify-center "
                                  sx={{ marginTop: "2%" }}>
                                  <Link to={`landDetails/${item.id}`}>
                                    <Box component="div" className="">
                                      <img
                                        src={
                                          item.Image ||
                                          "https://t3.ftcdn.net/jpg/04/46/93/92/360_F_446939257_j3c5pnVVJPnGoCdve7pjLRk5kLYICXzs.jpg"
                                        }
                                        alt="house Image"
                                        className="rounded-xl w-96 h-32"
                                      />
                                      <Box component="div">
                                        <Typography
                                          variant="subtitle1"
                                          sx={{
                                            fontWeight: "bold",
                                            textAlign: "left",
                                            fontSize: "12px",
                                          }}>
                                          {item.Address}
                                        </Typography>
                                        <Box className="flex items-center justify-between">
                                          <Box>
                                            <Typography
                                              variant="h1"
                                              sx={{
                                                opacity: "0.8",
                                                fontSize: "11px",
                                                textAlign: "left",
                                              }}>
                                              Size
                                            </Typography>
                                            <Typography
                                              variant="subtitle2"
                                              sx={{ fontSize: "12px" }}>
                                              {item.Size}
                                            </Typography>
                                          </Box>
                                          <Box>
                                            <Typography
                                              variant="h1"
                                              sx={{
                                                opacity: "0.8",
                                                fontSize: "11px",
                                                textAlign: "left",
                                              }}>
                                              Price
                                            </Typography>
                                            <Typography
                                              variant="subtitle2"
                                              sx={{ fontSize: "12px" }}>
                                              {item?.Currency}
                                              {item.Price.toLocaleString()}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Link>
                                </Grid>
                              );
                            })}
                          </Grid>
                        )}

                        {usersPerPage < properties?.length &&
                        properties?.length != 0 ? (
                          <Pagination
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            properties={properties}
                            indexOfLastUser={indexOfLastUser}
                            usersPerPage={usersPerPage}
                          />
                        ) : null}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default DashboardRealtor;
