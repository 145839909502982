import React, { useState } from "react";
import {
  Card,
  Typography,
  Row,
  Col,
  Button,
  Input,
  Alert,
  Modal,
  Upload,
  message,
  Radio,
} from "antd";
import { IdcardOutlined, UploadOutlined } from "@ant-design/icons";
import useOldPropertyApplicationForm from "../../hooks/form-hooks/useOldPropertyCofOApplicationForm";

import uniqueIdImage from "../../assets/uniqueId.jpg";
import PaymentModal from "../addons/PaymentModal";
import { Box } from "@mui/material";

const { Title, Paragraph } = Typography;

const inputStyle = {
  width: "100%",
  padding: "8px 12px",
  fontSize: "16px",
  border: "1px solid #d9d9d9",
  borderRadius: "2px",
  boxSizing: "border-box",
};

const OldPropertyApplicationForm = () => {
  const {
    formik,
    renderErrorMessage,
    isLoading,
    user,
    setOpenPaymentModal,
    handleOpenPaymentModal,
    handleInitializeCofO,
  } = useOldPropertyApplicationForm();

  const [isIdGuideVisible, setIsIdGuideVisible] = useState(false);

  // Handle file upload - modified to work correctly with Formik
  const handleFileUpload = ({ file, onSuccess, onError }) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2; // Less than 2MB

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG files!");
      onError("File type error");
      return;
    }
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
      onError("File size error");
      return;
    }

    // Set the file in Formik values
    formik.setFieldValue("passportPhoto", file);
    onSuccess("ok");
  };

  // Get file list from current formik value for Upload component
  const getFileList = () => {
    if (!formik.values.passportPhoto) return [];

    return [
      {
        uid: "-1",
        name: formik.values.passportPhoto.name || "photo",
        status: "done",
        url:
          formik.values.passportPhoto instanceof File
            ? URL.createObjectURL(formik.values.passportPhoto)
            : undefined,
        originFileObj: formik.values.passportPhoto,
      },
    ];
  };

  // Remove file
  const handleRemove = () => {
    formik.setFieldValue("passportPhoto", null);
    return true;
  };

  return (
    <div>
      <Card
        title={
          <Title level={3} className="text-center">
            Property With LockYourLand Due Diligence Certificate of Occupancy
            Application
          </Title>
        }
        style={{ marginBottom: 24 }}>
        {/* Personal Information Section */}
        <Title level={4}>Personal Information</Title>
        <Row gutter={24}>
          <Col xs={24} md={8}>
            <div className="mb-6">
              <label className="block mb-2 font-medium">Full Name *</label>
              <input
                type="text"
                style={inputStyle}
                disabled
                className="cursor-not-allowed bg-gray-100"
                value={`${user?.Firstname} ${user?.Lastname}`}
              />
            </div>
          </Col>
          <Col xs={24} md={8}>
            <div className="mb-6">
              <label className="block mb-2 font-medium">Email *</label>
              <input
                type="email"
                style={inputStyle}
                disabled
                className="cursor-not-allowed bg-gray-100"
                value={user?.Email}
              />
            </div>
          </Col>
          <Col xs={24} md={8}>
            <div className="mb-6">
              <label className="block mb-2 font-medium">Phone Number *</label>
              <input
                type="text"
                disabled
                className="cursor-not-allowed bg-gray-100"
                value={user?.Phone}
                style={inputStyle}
              />
            </div>
          </Col>
        </Row>

        {/* Form wrapping all fields */}
        <form onSubmit={formik.handleSubmit}>
          {/* Passport Photo Upload Section */}
          <Title level={4} className="mt-6">
            Passport Photograph
          </Title>
          <Alert
            message="Passport Photograph Requirements"
            description="Please upload a clear, passport-style photograph. The image must be in JPG or PNG format and less than 2MB in size."
            type="info"
            showIcon
            className="mb-4"
          />
          <Row gutter={24}>
            <Col xs={24} md={24}>
              <Upload
                name="passportPhoto"
                listType="picture-card"
                className="avatar-uploader"
                fileList={getFileList()}
                customRequest={handleFileUpload}
                onRemove={handleRemove}
                maxCount={1}
                accept=".jpg,.jpeg,.png">
                {formik.values.passportPhoto ? null : (
                  <div>
                    <UploadOutlined />
                    <div className="mt-2">Upload Photo</div>
                  </div>
                )}
              </Upload>
              {formik.touched.passportPhoto && formik.errors.passportPhoto && (
                <div className="text-red-500 mt-2">
                  {formik.errors.passportPhoto}
                </div>
              )}
            </Col>
          </Row>

          {/* Unique Property Identifier Section */}
          <Title level={4} className="mt-6">
            Property Unique Identifier
          </Title>
          <Alert
            message="Locate Your Unique Property ID"
            description="Please enter the unique property ID provided by LockYourLand. If you're unsure where to find this, click the 'Help' button below."
            type="info"
            showIcon
            className="mb-4"
          />

          <Row gutter={24}>
            <Col xs={24} md={18}>
              <Input
                id="uniqueId"
                name="uniqueId"
                size="large"
                placeholder="Enter Your Unique Property Identifier"
                prefix={<IdcardOutlined />}
                value={formik.values.uniqueId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="mb-4"
              />
              {renderErrorMessage("uniqueId")}
            </Col>
            <Col xs={24} md={6}>
              <Button
                type="dashed"
                onClick={() => setIsIdGuideVisible(true)}
                block
                className="mb-4">
                Help: Find My ID
              </Button>
            </Col>
          </Row>

          {/* Property Type Section */}
          <Title level={4} className="mt-6">
            Property Type
          </Title>
          <Row gutter={24}>
            <Col xs={24}>
              <Radio.Group
                id="propertyType"
                name="propertyType"
                value={formik.values.propertyType}
                onChange={(e) =>
                  formik.setFieldValue("propertyType", e.target.value)
                }
                onBlur={formik.handleBlur}>
                <Radio value="land">Land</Radio>
                <Radio value="building">Building</Radio>
              </Radio.Group>
              {formik.touched.propertyType && formik.errors.propertyType && (
                <div className="text-red-500 mt-2">
                  {formik.errors.propertyType}
                </div>
              )}
            </Col>
          </Row>

          <Box className="mt-5">
            <Title level={4} className="mt-6">
              Payment Breakdown
            </Title>

            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              1. <strong>Service Fee</strong> (Non-refundable). <br />
              2. <strong>C of O Application Fee</strong> (Amount to be confirmed
              post Legal Team Review).
            </Typography>
          </Box>

          {/* Submit Button */}
          <div className="text-center mt-6">
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              loading={isLoading}
              disabled={
                !formik.values.uniqueId ||
                !formik.values.passportPhoto ||
                !formik.values.propertyType ||
                isLoading
              }
              style={{
                minWidth: 200,
                height: "48px",
                fontSize: "16px",
                background: "#3b82f6",
              }}>
              {isLoading ? "Submitting..." : "Submit Application"}
            </Button>
            <PaymentModal
              handleOpenPaymentModal={handleOpenPaymentModal}
              setOpenPaymentModal={setOpenPaymentModal}
              handleInitializeCofO={handleInitializeCofO}
              amount={80000}
              CofOApplication={true}
            />
          </div>
        </form>
      </Card>

      {/* ID Guide Modal */}
      <Modal
        title="How to Find Your Unique Property ID"
        visible={isIdGuideVisible}
        onCancel={() => setIsIdGuideVisible(false)}
        footer={null}
        width={800}>
        <Paragraph>
          Your unique property ID can be found in the following location:
        </Paragraph>
        <ul>
          <li>In your LockYourLand account dashboard</li>
        </ul>
        <Paragraph type="secondary">
          If you cannot locate your unique property ID, please contact
          LockYourLand support.
        </Paragraph>
        <div className="flex items-center justify-center w-full bg-green-50 p-4">
          <img
            src={uniqueIdImage}
            alt="Unique Property ID Location"
            className="w-1/2 max-h-[400px] object-contain"
          />
        </div>
      </Modal>
    </div>
  );
};

export default OldPropertyApplicationForm;
