import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Radio,
  Row,
  Typography,
  Upload,
} from "antd";
import React from "react";
import useCofOApplicationForm from "../../hooks/form-hooks/useCofOApplicationForm";
import { DocumentList } from "../../utils/Index";
import PaymentModal from "../addons/PaymentModal";
import { Box } from "@mui/material";

const { Title, Paragraph, Text } = Typography;

const NewPropertyApplicationForm = () => {
  const {
    handleInitializeCofO,
    handleOpenPaymentModal,
    setOpenPaymentModal,
    loading,
    formik,
    propertyType,
    setPropertyType,
    handleDocumentCheck,
    uploadProps,
    renderErrorMessage,
    uploading,
    isLoading,
    user,
  } = useCofOApplicationForm();

  const inputStyle = {
    width: "100%",
    padding: "8px 12px",
    fontSize: "16px",
    border: "1px solid #d9d9d9",
    borderRadius: "2px",
    boxSizing: "border-box",
  };

  return (
    <div>
      {" "}
      <Card
        title={<Title level={3}>C of O Application Form</Title>}
        style={{ marginBottom: 24 }}>
        <form onSubmit={formik.handleSubmit}>
          {/* Personal Information */}
          <Title level={4}>Personal Information</Title>
          <Row gutter={24}>
            <Col xs={24} md={8}>
              <div style={{ marginBottom: "24px" }}>
                <label
                  style={{
                    display: "block",
                    marginBottom: "8px",
                    fontWeight: 500,
                  }}>
                  Full Name *
                </label>
                <input
                  type="text"
                  style={inputStyle}
                  disabled
                  className="cursor-pointer hover:bg-gray-200"
                  value={`${user?.Firstname} ${user?.Lastname}`}
                />
              </div>
            </Col>
            <Col xs={24} md={8}>
              <div style={{ marginBottom: "24px" }}>
                <label
                  style={{
                    display: "block",
                    marginBottom: "8px",
                    fontWeight: 500,
                  }}>
                  Email *
                </label>
                <input
                  style={inputStyle}
                  type="email"
                  disabled
                  className="cursor-pointer hover:bg-gray-200"
                  value={user?.Email}
                />
              </div>
            </Col>
            <Col xs={24} md={8}>
              <div style={{ marginBottom: "24px" }}>
                <label
                  style={{
                    display: "block",
                    marginBottom: "8px",
                    fontWeight: 500,
                  }}>
                  Phone Number *
                </label>
                <input
                  type="text"
                  disabled
                  className="cursor-pointer hover:bg-gray-200"
                  value={user?.Phone}
                  style={inputStyle}
                />
              </div>
            </Col>
            {/* <Col xs={24} md={12}>
              <div style={{ marginBottom: "24px" }}>
                <label
                  style={{
                    display: "block",
                    marginBottom: "8px",
                    fontWeight: 500,
                  }}>
                  Address *
                </label>
                <textarea
                  disabled
                  className="cursor-pointer hover:bg-gray-200"
                  value={user?.Address}
                  style={inputStyle}
                />
              </div>
            </Col> */}
          </Row>

          {/* Property Type Selection */}
          <div style={{ marginBottom: "24px" }}>
            <Title level={4}>Property Type</Title>
            <Radio.Group
              value={propertyType}
              onChange={(e) => setPropertyType(e.target.value)}
              style={{ marginBottom: "16px" }}>
              <Radio value="land">Vacant Land</Radio>
              <Radio value="building">Building</Radio>
            </Radio.Group>
            {formik.touched.propertyType && formik.errors.propertyType && (
              <div style={{ color: "#ff4d4f", fontSize: "14px" }}>
                {formik.errors.propertyType}
              </div>
            )}
          </div>

          <Divider />

          {/* Required Documents Section */}
          <div style={{ marginBottom: "32px" }}>
            <Title level={4}>
              Required Documents
              <Text
                type="danger"
                style={{ fontSize: "16px", marginLeft: "8px" }}>
                (Mandatory)
              </Text>
            </Title>

            <Row gutter={[24, 24]}>
              {/* Passport Photo - Always Required */}
              <Col xs={24} md={12}>
                <Card
                  size="small"
                  title="Passport Photograph"
                  extra={<Text type="danger">Required</Text>}
                  style={{
                    borderColor:
                      formik.touched.passportPhoto &&
                      formik.errors.passportPhoto
                        ? "#ff4d4f"
                        : "#d9d9d9",
                  }}>
                  <Upload {...uploadProps("PASSPORT_PHOTO")} listType="picture">
                    <Button icon={<UploadOutlined />}>
                      Upload Passport Photo
                    </Button>
                  </Upload>
                  {renderErrorMessage("passportPhoto")}
                </Card>
              </Col>

              {/* Deed of Sale - Always Required */}
              <Col xs={24} md={12}>
                <Card
                  size="small"
                  title="Deed of Sale"
                  extra={<Text type="danger">Required</Text>}
                  style={{
                    borderColor:
                      formik.touched.deedOfSale && formik.errors.deedOfSale
                        ? "#ff4d4f"
                        : "#d9d9d9",
                  }}>
                  <Upload {...uploadProps("DEED_OF_SALE")}>
                    <Button icon={<UploadOutlined />}>
                      Upload Deed of Sale
                    </Button>
                  </Upload>
                  {renderErrorMessage("deedOfSale")}
                </Card>
              </Col>

              {/* Survey Plan - Always Required (Added) */}
              <Col xs={24} md={12}>
                <Card
                  size="small"
                  title="Survey Plan"
                  extra={<Text type="danger">Required</Text>}
                  style={{
                    borderColor:
                      formik.touched.surveyPlan && formik.errors.surveyPlan
                        ? "#ff4d4f"
                        : "#d9d9d9",
                  }}>
                  <Upload {...uploadProps("SURVEY_PLAN")}>
                    <Button icon={<UploadOutlined />}>
                      Upload Survey Plan
                    </Button>
                  </Upload>
                  {renderErrorMessage("surveyPlan")}
                </Card>
              </Col>

              {/* Site Photos - Always Required (Added) */}
              <Col xs={24} md={12}>
                <Card
                  size="small"
                  title="Site Photos"
                  extra={<Text type="danger">Required (Upload up to 10)</Text>}
                  style={{
                    borderColor:
                      formik.touched.sitePhotos && formik.errors.sitePhotos
                        ? "#ff4d4f"
                        : "#d9d9d9",
                  }}>
                  <Upload {...uploadProps("SITE_PHOTOS")} listType="picture">
                    <Button icon={<UploadOutlined />}>
                      Upload Site Photos
                    </Button>
                  </Upload>
                  {formik.touched.sitePhotos && formik.errors.sitePhotos && (
                    <div
                      style={{
                        color: "#ff4d4f",
                        fontSize: "14px",
                        marginTop: "8px",
                      }}>
                      {typeof formik.errors.sitePhotos === "string"
                        ? formik.errors.sitePhotos
                        : "Please upload at least one site photo"}
                    </div>
                  )}
                  <div
                    style={{
                      marginTop: "8px",
                      fontSize: "12px",
                      color: "#999",
                    }}>
                    You can upload up to 10 photos of the property site.
                  </div>
                </Card>
              </Col>

              {/* Building Approval - Required only for buildings */}
              {propertyType === "building" && (
                <Col xs={24} md={12}>
                  <Card
                    size="small"
                    title="Building Approval"
                    extra={<Text type="danger">Required</Text>}
                    style={{
                      borderColor:
                        formik.touched.buildingApproval &&
                        formik.errors.buildingApproval
                          ? "#ff4d4f"
                          : "#d9d9d9",
                    }}>
                    <Upload {...uploadProps("BUILDING_APPROVAL")}>
                      <Button icon={<UploadOutlined />}>
                        Upload Building Approval
                      </Button>
                    </Upload>
                    {renderErrorMessage("buildingApproval")}
                  </Card>
                </Col>
              )}
            </Row>
          </div>

          {/* Additional Documents Section */}
          <div style={{ marginBottom: "32px" }}>
            <Title level={4}>
              Additional Supporting Documents
              <Text
                type="secondary"
                style={{ fontSize: "16px", marginLeft: "8px" }}>
                (Optional)
              </Text>
            </Title>

            <Alert
              message="Document Selection Guide"
              description="Select and upload any additional documents that apply to your property. The more documentation you provide, the smoother your application process will be."
              type="info"
              showIcon
              icon={<InfoCircleOutlined />}
              style={{ marginBottom: "24px" }}
            />

            <Row gutter={[24, 24]}>
              {Object.entries(DocumentList).map(([key, label]) => {
                // Skip the ones that are already handled as required
                if (
                  key === "DEED_OF_SALE" ||
                  key === "PASSPORT_PHOTO" ||
                  key === "SURVEY_PLAN" ||
                  key === "SITE_PHOTOS" ||
                  key === "BUILDING_APPROVAL"
                ) {
                  return null;
                }

                return (
                  <Col xs={24} md={8} key={key}>
                    <Card size="small" style={{ height: "100%" }}>
                      <Checkbox
                        checked={formik.values.selectedDocuments[key] || false}
                        onChange={() => handleDocumentCheck(key)}>
                        {label}
                      </Checkbox>

                      {formik.values.selectedDocuments[key] && (
                        <div style={{ marginTop: "12px" }}>
                          <Upload {...uploadProps(key)}>
                            <Button size="small" icon={<UploadOutlined />}>
                              Upload
                            </Button>
                          </Upload>
                        </div>
                      )}
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </div>

          <Box className="mt-5">
            <Title level={4} className="mt-6">
              Payment Breakdown
            </Title>

            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              1. <strong>Service Fee</strong> (Non-refundable). <br />
              2. <strong>C of O Application Fee</strong> (Amount to be confirmed
              post Legal Team Review).
            </Typography>
          </Box>

          {/* Submit Button */}
          <div style={{ marginTop: 40, textAlign: "center" }}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              disabled={isLoading}
              loading={uploading}
              style={{
                minWidth: 200,
                height: "48px",
                fontSize: "16px",
                background: "#3b82f6",
              }}>
              {isLoading ? "Submitting..." : "Submit Application"}
            </Button>
          </div>
        </form>
        <PaymentModal
          handleOpenPaymentModal={handleOpenPaymentModal}
          setOpenPaymentModal={setOpenPaymentModal}
          handleInitializeCofO={handleInitializeCofO}
          amount={80000}
          CofOApplication={true}
        />
      </Card>
    </div>
  );
};

export default NewPropertyApplicationForm;
