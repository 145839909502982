import { CheckCircleOutlined } from "@ant-design/icons";
import { Col, Row, Space, Typography } from "antd";
import cofoImage from "../../assets/composed-papers-house-miniature.png";
const { Title, Paragraph, Text } = Typography;

const Banner = () => {
  return (
    <div
      className="p-10"
      style={{
        background: "linear-gradient(to right, #1890ff, #52c41a)",
        color: "white",
      }}>
      <Row justify="center" align="middle">
        <Col xs={24} md={12}>
          <Title level={1} style={{ color: "white", margin: 0 }}>
            Certificate of Occupancy Application
          </Title>
          <Paragraph style={{ color: "white", fontSize: 18, marginTop: 16 }}>
            Due Diligence. Due Process. Dependable.
          </Paragraph>
          <Space direction="vertical" size="middle">
            <Space direction="vertical" size="small">
              <Paragraph
                style={{
                  color: "white",
                  fontSize: 14,
                  display: "flex",
                  alignItems: "center",
                  margin: 0,
                }}>
                <CheckCircleOutlined style={{ marginRight: 8 }} />
                Streamlined application process
              </Paragraph>
              <Paragraph
                style={{
                  color: "white",
                  fontSize: 14,
                  display: "flex",
                  alignItems: "center",
                  margin: 0,
                }}>
                <CheckCircleOutlined style={{ marginRight: 8 }} /> Expert
                document verification
              </Paragraph>
              <Paragraph
                style={{
                  color: "white",
                  fontSize: 14,
                  display: "flex",
                  alignItems: "center",
                  margin: 0,
                }}>
                <CheckCircleOutlined style={{ marginRight: 8 }} />
                Transparent tracking system
              </Paragraph>
              <Paragraph
                style={{
                  color: "white",
                  fontSize: 14,
                  display: "flex",
                  alignItems: "center",
                  margin: 0,
                }}>
                <CheckCircleOutlined style={{ marginRight: 8 }} /> Dedicated
                support team
              </Paragraph>
            </Space>

            <Paragraph
              style={{
                color: "white",
                fontSize: 14,
                marginTop: 16,
                opacity: 0.8,
              }}>
              Join property owners who have successfully secured their C of O
              through LockYourLand
            </Paragraph>
          </Space>
        </Col>
        <Col xs={24} md={12}>
          <div className="flex items-end justify-end">
            <img
              src={cofoImage}
              alt="Land documentation"
              style={{
                width: "80%",
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Banner;
