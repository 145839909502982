import axios from "axios";
import { API_URL } from "../api";

const getAllCofO = async (token, userId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/cofo/user/${userId}`, config);
  return response.data;
};

const getSingleCofO = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${API_URL}/cofo/user/${data.userId}/cofo/${data.cofoId}`,
    config
  );
  return response.data;
};

const requestCofO = async (user, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  };

  const response = await axios.post(
    `${API_URL}/cofo/initiate/${user._id}/${data.reference}`,
    data.propertyData,
    config
  );
  return response.data;
};

const requestCofOWithUniqueId = async (user, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  };

  const response = await axios.post(
    `${API_URL}/cofo/unique-id/initiate/${user._id}/${data.reference}`,
    data.propertyData,
    config
  );
  return response.data;
};

const validatePropertyWithUniqueId = async (user, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  };

  const response = await axios.post(
    `${API_URL}/cofo/check-unique-id/initiate/${user._id}`,
    data,
    config
  );
  return response.data;
};

const finalPaymentCofO = async (user, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${user?.token}`,
    },
  };

  const response = await axios.post(
    `${API_URL}/cofo/finalize/${user._id}/${data.cofoId}/${data.reference}`,
    {},
    config
  );
  return response.data;
};
const titlePerfectionService = {
  getAllCofO,
  getSingleCofO,
  requestCofO,
  requestCofOWithUniqueId,
  finalPaymentCofO,
  validatePropertyWithUniqueId,
};
export default titlePerfectionService;
