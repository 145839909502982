import {
  FileAddOutlined,
  IdcardOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Alert, Card, Col, Layout, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";

import Banner from "../components/CofO/Banner";
import InfoCard from "../components/CofO/InfoCard";
import Navbar from "../components/Navbar";
import NewPropertyApplicationForm from "../components/CofO/NewPropertyApplicationForm";
import OldPropertyApplicationForm from "../components/CofO/OldPropertyApplicationForm";
import { useSearchParams } from "react-router-dom";

const { Title, Text } = Typography;

const CofOApplication = () => {
  const [activeTab, setActiveTab] = useState("new");

  const [searchParams] = useSearchParams();
  const cofoType = searchParams.get("type");

  useEffect(() => {
    setActiveTab(cofoType || "new");
  }, [cofoType]); // Only updates when cofoType changes

  return (
    <>
      <Navbar />
      <Banner />

      <Layout.Content
        style={{ maxWidth: 1200, margin: "0 auto", padding: "48px 24px" }}>
        <InfoCard />

        {/* Application Type Selection */}
        <Row gutter={[16, 16]} style={{ marginBottom: 32 }}>
          <Col xs={24} md={12}>
            <Card
              hoverable
              style={{
                height: "100%",
                border:
                  activeTab === "new"
                    ? "2px solid #1890ff"
                    : "1px solid #d9d9d9",
              }}
              onClick={() => setActiveTab("new")}>
              <div style={{ textAlign: "center" }}>
                <FileAddOutlined
                  style={{ fontSize: 48, color: "#1890ff", marginBottom: 16 }}
                />
                <Title level={4}>New User Application</Title>
                <Text type="secondary">
                  Apply for Certificate of Occupancy if you haven't completed
                  due diligence
                </Text>
              </div>
            </Card>
          </Col>

          <Col xs={24} md={12}>
            <Card
              hoverable
              style={{
                height: "100%",
                border:
                  activeTab === "existing-user"
                    ? "2px solid #1890ff"
                    : "1px solid #d9d9d9",
              }}
              onClick={() => setActiveTab("existing-user")}>
              <div style={{ textAlign: "center" }}>
                <IdcardOutlined
                  style={{ fontSize: 48, color: "#1890ff", marginBottom: 16 }}
                />
                <Title level={4}>Existing User Application</Title>
                <Text type="secondary">
                  Submit your unique property ID for quick processing
                </Text>
              </div>
            </Card>
          </Col>
        </Row>

        {/* Conditional Rendering Based on Active Tab */}
        {activeTab === "new" && (
          <>
            <NewPropertyApplicationForm />
          </>
        )}

        {activeTab === "existing-user" && <OldPropertyApplicationForm />}

        {/* Important Notice */}
        <Alert
          message="Important Notice"
          description="Please ensure all documents are clear and legible. Original documents may be required for verification at a later stage. Our team will review your application within 3-5 business days."
          type="info"
          showIcon
          icon={<InfoCircleOutlined />}
          style={{ marginTop: 24 }}
        />
      </Layout.Content>
    </>
  );
};

export default CofOApplication;
