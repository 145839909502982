import { message, Typography, Upload } from "antd";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { cOfOvalidationSchema } from "../../utils/Index";
import { useDispatch, useSelector } from "react-redux";
import {
  finalPaymentCofO,
  getSingleCofO,
  requestCofO,
  reset,
} from "../../features/CofO/c_of_o_Slice";
import { useNavigate, useParams } from "react-router-dom";

const { Title, Paragraph, Text } = Typography;

const useCofOApplicationForm = () => {
  const [uploading, setUploading] = useState(false);
  const [propertyType, setPropertyType] = useState("land");
  const { user } = useSelector((state) => state.auth);
  const {
    singleCofO,
    isLoading,
    isSuccess,
    isError,
    message: msg,
  } = useSelector((state) => state.c_of_o);
  const dispatch = useDispatch();
  const [handleOpenPaymentModal, setOpenPaymentModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [propertyData, setPropertyData] = useState();

  const { cofoId } = useParams();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (cofoId) {
      dispatch(getSingleCofO(cofoId));
    }
  }, [dispatch, cofoId]);

  const formik = useFormik({
    initialValues: {
      userId: user?._id,
      propertyType: "land",
      passportPhoto: null,
      deedOfSale: null,
      surveyPlan: null,
      sitePhotos: [],
      buildingApproval: null,
      selectedDocuments: {},
      documentFiles: {},
    },
    validationSchema: cOfOvalidationSchema,
    onSubmit: (values) => {
      console.log(values);
      // Create a FormData object to properly handle file uploads
      const formData = new FormData();

      // Add basic fields
      formData.append("userId", values.userId);
      formData.append("propertyType", values.propertyType);

      // Append individual files
      if (values.passportPhoto) {
        formData.append("passportPhoto", values.passportPhoto);
      }

      if (values.deedOfSale) {
        formData.append("deedOfSale", values.deedOfSale);
      }

      if (values.surveyPlan) {
        formData.append("surveyPlan", values.surveyPlan);
      }

      if (values.buildingApproval) {
        formData.append("buildingApproval", values.buildingApproval);
      }

      values.sitePhotos.forEach((photo, index) => {
        formData.append("sitePhotos", photo);
      });

      // Handle selected documents (convert to JSON string)
      formData.append(
        "selectedDocuments",
        JSON.stringify(values.selectedDocuments)
      );

      // Convert document files to additionalDocuments array
      // Add document type metadata with each file
      Object.entries(values.documentFiles).forEach(([docType, file]) => {
        // Append each file to the additionalDocuments field
        formData.append("additionalDocuments", file);
      });

      setPropertyData(formData);
      setOpenPaymentModal(true);
    },
  });

  useEffect(() => {
    formik.setFieldValue("propertyType", propertyType);
  }, [propertyType]);

  const handleDocumentCheck = (docKey) => {
    const currentSelected = { ...formik.values.selectedDocuments };
    currentSelected[docKey] = !currentSelected[docKey];
    formik.setFieldValue("selectedDocuments", currentSelected);
  };

  const uploadProps = (docKey) => {
    // Base configuration for all uploads
    const baseConfig = {
      name: "file",
      customRequest: ({ file, onSuccess }) => {
        if (docKey === "PASSPORT_PHOTO") {
          formik.setFieldValue("passportPhoto", file);
        } else if (docKey === "DEED_OF_SALE") {
          formik.setFieldValue("deedOfSale", file);
        } else if (docKey === "SURVEY_PLAN") {
          formik.setFieldValue("surveyPlan", file);
        } else if (docKey === "SITE_PHOTOS") {
          file.uid = file.uid || `${Date.now()}-${file.name}`; // Ensure unique uid

          // Use fileList to correctly merge multiple selections
          formik.setFieldValue("sitePhotos", (prev) => {
            const updatedPhotos = Array.isArray(prev)
              ? [...prev, file]
              : [file];
            return updatedPhotos;
          });
        } else if (docKey === "BUILDING_APPROVAL") {
          formik.setFieldValue("buildingApproval", file);
        } else {
          formik.setFieldValue("documentFiles", {
            ...formik.values.documentFiles,
            [docKey]: file,
          });
        }

        // Simulate successful upload
        setTimeout(() => {
          onSuccess("ok", null);
        }, 100);
      },
      showUploadList: true,
      beforeUpload: (file) => {
        const isImage = ["PASSPORT_PHOTO", "SITE_PHOTOS"].includes(docKey);
        if (isImage && !file.type.startsWith("image/")) {
          message.error("You can only upload image files!");
          return Upload.LIST_IGNORE;
        }
        if (file.size / 1024 / 1024 >= 7) {
          message.error("File must be smaller than 7MB!");
          return Upload.LIST_IGNORE;
        }
        return true; // Let customRequest handle the file
      },
      onChange: (info) => {
        const { file, fileList } = info;

        if (docKey === "SITE_PHOTOS") {
          // Ensure Formik updates the whole array correctly
          formik.setFieldValue(
            "sitePhotos",
            fileList.map((file) => file.originFileObj || file)
          );
        }

        // Handle upload state changes
        if (file.status === "uploading") {
          setUploading(true);
        }
        if (file.status === "done") {
          setUploading(false);
          message.success(`${file.name} uploaded successfully`);
        } else if (file.status === "error") {
          setUploading(false);
          message.error(`${file.name} upload failed.`);
        }
      },
    };

    // Special configuration for site photos to allow multiple uploads
    if (docKey === "SITE_PHOTOS") {
      return {
        ...baseConfig,
        multiple: true,
        onRemove: (file) => {
          const updatedFiles = formik.values.sitePhotos.filter(
            (item) => item.uid !== file.uid
          );
          formik.setFieldValue("sitePhotos", updatedFiles);
        },
      };
    }

    return {
      ...baseConfig,
      multiple: false,
    };
  };

  const renderErrorMessage = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return (
        <div style={{ color: "#ff4d4f", fontSize: "14px", marginTop: "4px" }}>
          {formik.errors[fieldName]}
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    if (isSuccess && msg == "Request sent successfully") {
      message.success(msg);
      dispatch(reset());
      navigate(`/dashboard/cofoApplication`);
    }

    if (isSuccess && msg == "Final Payment successful") {
      message.success(msg);
      navigate(0);
      dispatch(reset());
    }
    if (isError) {
      message.error(msg);
      dispatch(reset());
    }
  }, [isSuccess, isError]);

  function handleInitializeCofO(reference) {
    // Dispatch with FormData
    dispatch(requestCofO({ propertyData, reference }));
    setLoading(true);
  }

  function handlePaymentOfCofO(reference) {
    dispatch(finalPaymentCofO({ cofoId, reference }));
    setLoading(true);
  }

  return {
    handleInitializeCofO,
    setOpenPaymentModal,
    loading,
    handleOpenPaymentModal,
    formik,
    propertyType,
    setPropertyType,
    handleDocumentCheck,
    uploadProps,
    renderErrorMessage,
    uploading,
    user,
    isLoading,
    isError,
    tabValue,
    setTabValue,
    singleCofO,
    handlePaymentOfCofO,
  };
};

export default useCofOApplicationForm;
