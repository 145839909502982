import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../components/addons/Loader";
import NotFound from "../components/addons/NotFound";
import Pagination from "../components/addons/Pagination";
import DashboardNavbar from "../components/DashboardNavbar";
import UserDetails from "../components/userInformation/UserDetails";
import { reset } from "../features/land/landSlice";
import { getAllCofO } from "../features/CofO/c_of_o_Slice";

const DashboardRealtorCofO = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(4);
  const [searchTerm, setSearchTerm] = useState("");

  const { cofos, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.c_of_o
  );

  useEffect(() => {
    dispatch(getAllCofO());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        onClose: () => {
          dispatch(reset());
        },
      });
    }

    dispatch(reset());
  }, [isError, isSuccess, message, dispatch]);

  // Filter CofO applications based on search term
  const filteredCofos = cofos?.data?.filter(
    (item) =>
      item?.CofO?.applicationId
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      item?.CofO?.propertyType
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      item?.agent?.Firstname?.toLowerCase().includes(
        searchTerm.toLowerCase()
      ) ||
      item?.agent?.Lastname?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginatedItems = filteredCofos?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  // Function to get status color
  const getStatusColor = (status) => {
    switch (status) {
      case "approved":
        return "success";
      case "rejected":
        return "error";
      case "awaiting":
        return "warning";
      default:
        return "default";
    }
  };

  return (
    <>
      <DashboardNavbar />
      <Box component="div" sx={{ height: "90vh" }} className="">
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} md={3}>
              <UserDetails />
            </Grid>
            <Grid item xs={12} sm={6} md={9}>
              <Box
                component="div"
                className="sm:p-5 p-5 bg-green-custom custom-scrollbar"
                sx={{ height: "90vh", overflow: "auto" }}>
                <Box component="div" className="p-3 mt-3 sm:mt-0 w-full">
                  <Link to="/dashboard">
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        opacity: "90%",
                        marginBottom: "15px",
                      }}>
                      Go Back
                    </Typography>
                  </Link>
                  <Box component="div" className="p-2">
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        textAlign: "center",
                        marginBottom: "20px",
                      }}>
                      Certificate of Occupancy Applications
                    </Typography>

                    {cofos?.data?.length > 0 && (
                      <Box className="my-3">
                        <TextField
                          id="standard-search"
                          label="Search applications"
                          type="search"
                          fullWidth
                          variant="outlined"
                          size="small"
                          placeholder="Search by ID, property type, or agent name"
                          onChange={(e) => setSearchTerm(e.target.value)}
                          sx={{ marginBottom: "20px" }}
                        />
                      </Box>
                    )}

                    {isLoading ? (
                      <Loader />
                    ) : isError ? (
                      <NotFound />
                    ) : !filteredCofos || filteredCofos.length === 0 ? (
                      <NotFound message="No Certificate of Occupancy applications found" />
                    ) : (
                      <Grid container spacing={2}>
                        {paginatedItems.map((item, index) => (
                          <Grid item xs={12} md={6} key={item?.CofO?._id}>
                            <Card
                              sx={{
                                display: { xs: "block", sm: "flex" },
                                marginBottom: 2,
                                flexDirection: { xs: "column", sm: "row" },
                              }}>
                              <CardMedia
                                component="img"
                                sx={{
                                  width: { xs: "100%", sm: 150 },
                                  height: { xs: 250, sm: 150 },
                                  objectFit: "cover",
                                }}
                                image={
                                  item.CofO.sitePhotos[0]?.url ||
                                  "https://t3.ftcdn.net/jpg/04/46/93/92/360_F_446939257_j3c5pnVVJPnGoCdve7pjLRk5kLYICXzs.jpg"
                                }
                                alt="Property Photo"
                              />
                              <CardContent
                                sx={{
                                  flex: { xs: "none", sm: "1 0 auto" },
                                  padding: 2,
                                }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: 1,
                                  }}>
                                  <Typography
                                    component="div"
                                    variant="h6"
                                    sx={{ fontWeight: "bold" }}>
                                    {item?.CofO?.applicationId}
                                  </Typography>
                                  <Chip
                                    label={item?.CofO?.status.toUpperCase()}
                                    color={getStatusColor(item?.CofO?.status)}
                                    size="small"
                                  />
                                </Box>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                  sx={{ marginBottom: 1 }}>
                                  Property Type:{" "}
                                  <strong>
                                    {item?.CofO?.propertyType?.toUpperCase()}
                                  </strong>
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                  sx={{ marginBottom: 1 }}>
                                  Agent:{" "}
                                  {item?.agent ? (
                                    <strong>
                                      {item?.agent?.Firstname}{" "}
                                      {item?.agent?.Lastname}
                                    </strong>
                                  ) : (
                                    <strong>Not Assigned</strong>
                                  )}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                  sx={{ marginBottom: 1 }}>
                                  Payment:{" "}
                                  <strong>
                                    {item?.CofO?.paymentStatus?.toUpperCase()}
                                  </strong>{" "}
                                  (₦{item?.CofO?.paymentDue})
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="text.secondary">
                                  Submitted:{" "}
                                  {formatDate(item?.CofO?.submissionDate)}
                                </Typography>
                                <Link
                                  to={`/dashboard/cofoApplication/details/${item?.CofO?._id}`}
                                  style={{ textDecoration: "none" }}>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: "#22c55e",
                                      marginTop: 1,
                                      fontWeight: "bold",
                                      cursor: "pointer",
                                    }}>
                                    View Details →
                                  </Typography>
                                </Link>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    )}

                    {filteredCofos && filteredCofos.length > itemsPerPage && (
                      <Box>
                        <Pagination
                          setCurrentPage={setCurrentPage}
                          currentPage={currentPage}
                          properties={filteredCofos.length}
                          indexOfLastUser={indexOfLastItem}
                          usersPerPage={itemsPerPage}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default DashboardRealtorCofO;
