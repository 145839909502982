// src/hooks/useInactivityLogout.js
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../features/auth/authSlice";

export const useInactivityLogout = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!user) return; // Exit early if no user is logged in

    const INACTIVITY_TIMEOUT = 15 * 60 * 1000; // 15 minutes in milliseconds
    let timeoutId;

    const resetTimer = () => {
      clearTimeout(timeoutId); // Clear any existing timeout
      timeoutId = setTimeout(() => {
        dispatch(logout()); // Trigger logout after inactivity
      }, INACTIVITY_TIMEOUT);
    };

    // List of events to monitor for user activity
    const events = ["mousemove", "keydown", "scroll", "click"];
    events.forEach((event) => window.addEventListener(event, resetTimer));

    resetTimer(); // Start the timer immediately

    // Cleanup function
    return () => {
      clearTimeout(timeoutId); // Clear the timeout
      events.forEach((event) => window.removeEventListener(event, resetTimer)); // Remove listeners
    };
  }, [user, dispatch]); // Dependency array
};
