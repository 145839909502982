import React from "react";
import {
  Button,
  Grid,
  Box,
  Typography,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import PaymentModal from "../../components/addons/PaymentModal";
import { CheckCircleIcon, CreditCardIcon, AlertCircleIcon } from "lucide-react";

const InitiateFinalPayment = ({
  isLoading,
  setOpenPaymentModal,
  handleOpenPaymentModal,
  handleFinalPaymentVerification,
  document,
}) => {
  const finalPaymentAmount =
    document?.UnbalancedPayment + document?.AgentUnbalancedPayment;

  // Format the amount with commas for thousands
  const formattedAmount = finalPaymentAmount.toLocaleString("en-US", {
    style: "currency",
    currency: "NGN",
    maximumFractionDigits: 0,
  });

  return (
    <Paper
      elevation={0}
      sx={{
        padding: 3,
        borderRadius: 2,
        border: "1px solid #e2e8f0",
        background: "linear-gradient(145deg, #ffffff 0%, #f8fafc 100%)",
      }}>
      <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
        <CheckCircleIcon size={22} color="#10b981" className="mr-3" />
        <Typography
          variant="h6"
          sx={{
            fontSize: "1rem",
            fontWeight: 700,
            color: "#1e293b",
          }}>
          Search & Survey Complete
        </Typography>
      </Box>

      <Box
        sx={{
          p: 2,
          mb: 3,
          borderRadius: 2,
          backgroundColor: "#f0fdf4",
          border: "1px solid #dcfce7",
        }}>
        <Typography
          variant="body2"
          sx={{
            fontSize: "0.875rem",
            color: "#166534",
            fontWeight: 500,
            mb: 1.5,
          }}>
          The following documents are ready for you:
        </Typography>

        <List dense sx={{ p: 0 }}>
          <ListItem sx={{ p: 0.5 }}>
            <ListItemIcon sx={{ minWidth: 28 }}>
              <CheckCircleIcon size={16} color="#16a34a" />
            </ListItemIcon>
            <ListItemText
              primary="CAC search document"
              primaryTypographyProps={{
                sx: { fontSize: "0.85rem", color: "#047857" },
              }}
            />
          </ListItem>
          <ListItem sx={{ p: 0.5 }}>
            <ListItemIcon sx={{ minWidth: 28 }}>
              <CheckCircleIcon size={16} color="#16a34a" />
            </ListItemIcon>
            <ListItemText
              primary="Probate Registry search document"
              primaryTypographyProps={{
                sx: { fontSize: "0.85rem", color: "#047857" },
              }}
            />
          </ListItem>
          <ListItem sx={{ p: 0.5 }}>
            <ListItemIcon sx={{ minWidth: 28 }}>
              <CheckCircleIcon size={16} color="#16a34a" />
            </ListItemIcon>
            <ListItemText
              primary="Land Registry search document"
              primaryTypographyProps={{
                sx: { fontSize: "0.85rem", color: "#047857" },
              }}
            />
          </ListItem>
          <ListItem sx={{ p: 0.5 }}>
            <ListItemIcon sx={{ minWidth: 28 }}>
              <CheckCircleIcon size={16} color="#16a34a" />
            </ListItemIcon>
            <ListItemText
              primary="Comprehensive survey document"
              primaryTypographyProps={{
                sx: { fontSize: "0.85rem", color: "#047857" },
              }}
            />
          </ListItem>
        </List>
      </Box>

      <Box
        sx={{
          p: 2,
          mb: 3,
          borderRadius: 2,
          backgroundColor: "#fffbeb",
          border: "1px solid #fef3c7",
          display: "flex",
          alignItems: "flex-start",
        }}>
        <AlertCircleIcon size={20} color="#d97706" className="mr-2 mt-0.5" />
        <Typography
          variant="body2"
          sx={{
            fontSize: "0.875rem",
            color: "#92400e",
            fontWeight: 500,
          }}>
          To access these documents, please complete the final payment of{" "}
          {formattedAmount}
        </Typography>
      </Box>

      <Button
        variant="contained"
        disableElevation
        disabled={isLoading}
        fullWidth
        startIcon={<CreditCardIcon size={18} />}
        onClick={() => setOpenPaymentModal(!handleOpenPaymentModal)}
        sx={{
          py: 1.5,
          backgroundColor: "#047857",
          color: "#fff",
          textTransform: "none",
          fontWeight: 600,
          fontSize: "0.9rem",
          borderRadius: "8px",
          boxShadow: "0 4px 6px rgba(4, 120, 87, 0.2)",
          "&:hover": {
            backgroundColor: "#065f46",
            transform: "translateY(-1px)",
            boxShadow: "0 6px 8px rgba(4, 120, 87, 0.25)",
          },
        }}>
        {isLoading ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <span className="animate-pulse mr-2">●</span>
            <span>Processing...</span>
          </Box>
        ) : (
          "Complete Final Payment"
        )}
      </Button>

      <PaymentModal
        handleOpenPaymentModal={handleOpenPaymentModal}
        setOpenPaymentModal={setOpenPaymentModal}
        handleFinalPaymentVerification={handleFinalPaymentVerification}
        amount={finalPaymentAmount}
        finalPayment={true}
      />
    </Paper>
  );
};

export default InitiateFinalPayment;
